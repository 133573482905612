import { mapValues } from 'lodash';

export type Permission = string;

export enum AssetTypePermissions {
  Car = 'asset_car',
  Uav = 'asset_uav',
  Gcs = 'asset_gcs',
  Beacon = 'asset_beacon',
  GeoGraphics = 'asset_geographics',
  Camera = 'asset_ptz',
  Afu = 'asset_afu',
}

// тут рано или поздно перечислим все пермишки
// Permissions = AssetTypePermissions | OtherPermissions | ...
// 
// не стесняйтесь - добавляйте свои по мере необходимости.
export type Permissions = AssetTypePermissions; 

export const PERMISSIONS_ALL_ASSETS_TYPES: readonly Permission[] = [
  AssetTypePermissions.Afu, 
  AssetTypePermissions.Beacon, 
  AssetTypePermissions.Camera,
  AssetTypePermissions.Car, 
  AssetTypePermissions.Gcs,
  AssetTypePermissions.GeoGraphics, 
  AssetTypePermissions.Uav,
]