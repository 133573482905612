import '../styles.scss';

import { FC, useRef } from 'react';
import { Map } from '@/shared/map';
import {
  CursorCoordinatesPanel,
  HeatLayer,
  Measure,
  SearchOnArea,
  TooltipOverlay,
  ZoomPanel,
  ZoomPosition,
} from '@/features/map';
import { FlightsByDateAndArea } from '@/widgets/FlightsByDateAndArea';
import { TrackLayer } from '@/features/map/layers/trackLayer';
import { TrackData } from '@/entities/flight';
import { GasAttachment } from '@/entities/attachment';
import { Style } from 'ol/style';

interface Props {
  readonly gas: GasAttachment;
  readonly track?: TrackData;
}

const emptyTrackStyles = new Style();

export const Gas: FC<Props> = ({ gas, track }) => {
  const mapTools = useRef<HTMLInputElement>(null);

  return (
    <>
      <div className='gasMap'>
        <Map>
          <div ref={mapTools} className='map-tools'></div>

          <TooltipOverlay />

          <ZoomPanel position={ZoomPosition.BaseLayoutTopRight} />

          <SearchOnArea target={mapTools} widgetResult={<FlightsByDateAndArea />} pluginName={'searchOnArea'} />

          <Measure ruleTarget={mapTools} areaTarget={mapTools} clearTarget={mapTools} pluginName={'measure'} />

          <TrackLayer track={track} style={emptyTrackStyles} />

          <HeatLayer tracks={[gas]} />

          <CursorCoordinatesPanel />
        </Map>
      </div>
    </>
  );
};
