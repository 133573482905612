import '../styles.scss';

import React, { FC, ReactNode, useRef } from 'react';
import { FLIGHT_CARD_LAYERS, Map } from '@/shared/map';
import {
  CursorCoordinatesPanel,
  Measure,
  SearchOnArea,
  ZoomPanel,
  ZoomPosition,
} from '@/features/map';
import { LayersPanel, LayersPanelPosition } from '@/features/map/LayersPanel';
import { FlightsByDateAndArea } from '@/widgets/FlightsByDateAndArea';
import { SinglePointLayer } from '@/features/map/layers';
import { TrackLayer } from '@/features/map/layers/trackLayer';
import { PointsLayer } from '@/features/map/layers/pointsLayer';
import { dep, diInject } from '@/HOC';
import { SimpleMapStore } from '@/entities/simpleMap';

interface Props {
  readonly store: SimpleMapStore;
  readonly children?: ReactNode;
}

const SimpleMapCard: FC<Props> = ({store, children}) => {
  const mapTools = useRef<HTMLInputElement>(null);

  return (
    <>
      <div className='simpleMapCard'>
        <div ref={mapTools} className='map-tools'></div>
        <Map>

          <ZoomPanel position={ZoomPosition.BaseLayoutTopRight}/>

          <SearchOnArea
            target={mapTools}
            widgetResult={<FlightsByDateAndArea />}
            pluginName={'searchOnArea'}
          />

          <Measure
            ruleTarget={mapTools}
            areaTarget={mapTools}
            clearTarget={mapTools}
            pluginName={'measure'}
          />

          <LayersPanel
            position={LayersPanelPosition.TopRight}
            target={mapTools}
            layerSortingTemplate={[...FLIGHT_CARD_LAYERS]}
          />

          <SinglePointLayer coordinates={store.hoveredPoint} focusOnPoint={store.focusOnHoveredPoint} />

          <PointsLayer points={store.points} />

          <TrackLayer track={store.track} />

          <CursorCoordinatesPanel />

          {children}

        </Map>
      </div>
    </>
  )
}

const inject =  diInject(SimpleMapCard, {
  store: dep(SimpleMapStore),
})

export { inject as SimpleMapCard }
