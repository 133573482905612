import { useMemo } from 'react';
import { Languages } from '../model';
import { useStore } from '../store/context';
import { createIntl, createIntlCache } from 'react-intl';

const intlCache = createIntlCache();

export const useIntlSetup = () => {
  const { localeStore, i18nService } = useStore();

  const intl = useMemo(() => {
    const i = createIntl(
      {
        locale: localeStore.locale.code,
        messages: localeStore.libLocales?.intl,
        defaultLocale: Languages.RU,
      },
      intlCache,
    );
    i18nService.setLocale(i);
    return i;
  }, [localeStore.locale.code, localeStore.libLocales?.intl, intlCache]);

  return intl;
};
