import { makeAutoObservable } from 'mobx';
import { injectable } from 'inversify';
import { SortableFileProperties, UploadHistoryFile } from './interfaces';
import { createUploadHistoryFileApi, type UploadHistoryApi } from '../api/uploadHistoryFile.api';
import { ApiQueryBuilder } from '@workspace/4Z1.ts.utils';


@injectable()
export class UploadHistoryService {
  public static readonly diKey = Symbol.for('UploadHistoryService');

  constructor(private readonly api: UploadHistoryApi = createUploadHistoryFileApi()) {
    makeAutoObservable(this);
  }

  public async fetchFilesByFlightId(query: ApiQueryBuilder<SortableFileProperties>): Promise<readonly UploadHistoryFile[]> {
    return this.api.fetchFiles(query);
  }
}
