import { makeAutoObservable } from 'mobx';
import { injectable } from 'inversify';
import { TrackData } from '@/entities/flight';
import { Coordinates } from '@workspace/4Z1.ts.utils';

@injectable()
export class SimpleMapStore {
  public static readonly diKey = Symbol.for('SimpleMapStore');

  private _track: TrackData | undefined;
  private _hoveredPoint: Coordinates | undefined;
  private _points: Coordinates[] | undefined;
  private _focusOnHoveredPoint: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  public get track(): TrackData | undefined {
    return this._track;
  }

  public get hoveredPoint(): Coordinates | undefined {
    return this._hoveredPoint;
  }

  public get points(): Coordinates[] | undefined {
    return this._points;
  }

  public get focusOnHoveredPoint(): boolean {
    return this._focusOnHoveredPoint;
  }

  public setTrack(track: TrackData | undefined): void {
    this._track = track;
  }

  public setHoveredPoint(point: Coordinates | undefined): void {
    this._hoveredPoint = point;
  }

  public setPoints(points: Coordinates[] | undefined): void {
    this._points = points;
  }

  public setFocusOnHoveredPoint(value: boolean): void {
    this._focusOnHoveredPoint = value;
  }
}
