export default {
  'menu.home': 'Home',
  'menu.flights': 'Flights',
  'menu.photos': 'Photo archives',
  'menu.videos': 'Videos',
  'menu.ofps': 'Orthomosaics',
  'menu.downloads': 'Downloads',
  'menu.ofp_swipe' : 'Orthomosaics.Swipe',
  'menu.ofp_compare': 'Orthomosaics.Compare',
  'menu.ofp_compare_cd': 'Orthomosaics.GeoDynamics',
  'menu.admin': 'Admin',
  'menu.Profile': 'Profile',
  'menu.Massload': 'Upload data',
  'menu.login': 'Login',
  'menu.logout': 'Logout',
  'menu.online': 'Streams',
  'menu.gazes': 'Gas analyzer',
  'menu.Statistics': 'Statistics',
  'menu.Routes': 'Routes',
  'menu.Tiles': 'Tiles',
  'menu.Admin menu': 'Admin menu',
  'menu.orders': 'Orders',
  'menu.reports': 'Reports',
  'menu.cdreports': 'GeoDynamics',
  'menu.UserAccess': 'User Access',
  'menu.Heatmap': 'Heatmap',
  'menu.Download':'Download maps',
  'menu.TiEnOrchestrator': 'TiEn Orchestrator',
};
