import { makeAutoObservable } from 'mobx';
import { Flight as OldFlight } from '@/.graphql/graphql';
import {
  State,
  StateHandler,
  StateHandlerStatus,
} from '@/shared/utils';
import { inject, injectable } from 'inversify';
import { FtpFormStore } from '@/features/ftpForm/model/ftpForm.store';
import {
  createFlightApi,
  DownloadKmlParams,
  type FlightApi,
} from '../api/flight.api';
import RequestHandler from '@/shared/utils/requestHandler';
import { isNil } from 'lodash';
import { FlightMeta } from '@/entities/FlightListServices/interface';
import { Formats } from '@/entities/fileTypes';
import { Flight as FlightRest } from '../api/flight.api';

export interface FlightServiceState {
  readonly flight: OldFlight;
  readonly isFtpEnabled: boolean;
}

@injectable()
export class FlightService {
  public static readonly diKey = Symbol.for('FlightService');

  private ftpService: FtpFormStore;

  constructor(
    /**
     * TODO - Исправить
     *
     * Ниже перед нами никакой не ftpService, а обычный стор FTP формы
     * Необходимо отделить логику формы от сервиса и инжектить сюда именно сервис FTP
     */

    @inject(FtpFormStore.diKey) ftpService: FtpFormStore,
    private readonly api: FlightApi = createFlightApi(),
    private readonly requestHandler = new RequestHandler<OldFlight | undefined>(),
  ) {
    this.ftpService = ftpService;
    makeAutoObservable(this);
  }

  /**
   * TODO - Исправить
   *
   * @deprecated - брать данные напрямую из state
   */
  public get data(): FlightServiceState {
    if (this.state.status !== StateHandlerStatus.Ready) {
      throw new Error('Cannot access data before initialization');
    }

    return this.state.data;
  }

  public get state(): State<FlightServiceState, unknown> {
    const { data, isLoading, error } = this.requestHandler;

    if (isLoading) {
      return StateHandler.loading();
    }

    if (!isNil(error) || isNil(data)) {
      return StateHandler.error(error);
    }

    if (StateHandler.isReady(this.ftpService.state) && !isNil(data)) {
      return StateHandler.ready({
        flight: data,
        isFtpEnabled: this.ftpService.state.data?.isFtpEnabled ?? false,
      });
    }

    return StateHandler.loading();
  }

  private get flight(): OldFlight | undefined {
    if (!StateHandler.isReady(this.state)) return;

    return this.state.data.flight;
  }

  public async fetchFlight(flightId: string): Promise<OldFlight | undefined> {
    if (isNil(flightId)) {
      throw new Error('Cannot view flight without a flight id');
    }

    return this.requestHandler
      .handleRequest(() => this.api.fetchFlight(flightId))
      .then(flight => {
        this.requestFtp(flight);
        return flight;
      });
  }

  public async deleteFlight(): Promise<unknown> {
    if (this.flight === undefined) return;

    return this.api.deleteFlight(this.flight.flight_id);
  }

  private requestFtp(flight: OldFlight | undefined): void {
    if (flight?.flight_id) {
      this.ftpService.setFlightId(flight?.flight_id);
      this.ftpService.requestData();
    }
  }

  /**
   * @deprecated
   * @see - (method) downloadFlightKml
   */
  public async downloadKmlAttachments(
    flight: DownloadKmlParams,
  ): Promise<unknown> {
    return this.api.downloadKmlAttachments(flight);
  }

  public async downloadFlight(
    flightId: string,
  ): Promise<void> {
    return this.api.downloadFlight(flightId)
  }

  public async downloadFlightTlm(
    flight: FlightMeta,
  ): Promise<unknown> {
    return this.api.downloadFlightTelemetry(flight.id, flight.projectId);
  }

  public async downloadFlightKml(
    flight: FlightMeta,
  ): Promise<unknown> {
    return this.api.downloadFlightTelemetry(flight.id, flight.projectId, Formats.KML);
  }

  public async saveFlight(
    data: FlightRest,
    crew: readonly { id: string, title: string }[],
    crewNumber: string,
    workflowStatus?: string,
  ) {

    return this.api.saveFlightData(data, crew, crewNumber, workflowStatus);
  }
}
