import React from 'react';
import { useModel, history, useAccess, FormattedMessage } from 'umi';
import { Menu, Dropdown } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';

import {UserLoginPath, CurrentUserKey, CurrentOptionsKey} from "@/services/UrqlClientProvider";

const BottomMenu: React.FC = () => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const access = useAccess();

  const onMenuClick: MenuProps['onClick'] = e => {
    const { key } = e;
    if (key === '/user/logout' && initialState) {
      setInitialState({ ...initialState, currentUser: undefined, currentOptions: undefined });
      localStorage.removeItem(CurrentUserKey);
      localStorage.removeItem(CurrentOptionsKey);
      window.location.pathname = UserLoginPath;
      return;
    }
    history.push(`${key}`);
  };

  type MenuItem = Required<MenuProps>['items'][number] | null;

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const SubAdminMenu = [
    (access['zalamassload']) ? getItem(<FormattedMessage id="menu.Massload" defaultMessage="Massload" />, '/admin/massload') : null,
    (access['zalaroute/create']) ? getItem(<FormattedMessage id="menu.Routes" defaultMessage="Routes" />, '/admin/route') : null,
    (access['zalatile/create']) ? getItem(<FormattedMessage id="menu.Tiles" defaultMessage="Tiles" />, '/admin/tile') : null,
    (access['logger']) ? getItem(<FormattedMessage id="menu.Statistics" defaultMessage="Statistics" />, '/admin/statistic') : null,
    (access['canAdmin']) ? getItem(<FormattedMessage id="menu.UserAccess" defaultMessage="User Access" />, '/admin/useraccess') : null,
    (access['canAdmin']) ? getItem(<FormattedMessage id="menu.Assets" defaultMessage="Assets" />, '/admin/assets') : null,
    (access['canAdmin'] && access['dtoe']) ? getItem(<FormattedMessage id="menu.Download" defaultMessage="Download Maps" />, '/admin/dtoe') : null,
    (access['canAdmin'] && access['dtoe']) ? getItem(<FormattedMessage id="menu.TiEnOrchestrator" defaultMessage="TiEn Orchestrator" />, '/admin/tien') : null,
  ];

  const AdminMenu: MenuItem = (access['canAdmin']) ?
    getItem(<FormattedMessage id="menu.Admin menu" defaultMessage="Admin menu" />, 'admin', '', SubAdminMenu) :
    null;

  const items: MenuProps['items'] = [
    AdminMenu,
    getItem(<FormattedMessage id="menu.Profile" defaultMessage="Profile" />, '/user/update'),
    getItem(<FormattedMessage id="menu.logout" defaultMessage="Logout" />, '/user/logout'),
  ];

  return (
    <Dropdown menu={{items, onClick: onMenuClick}} trigger={['click']}>
      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
        <UserOutlined /><span className="ant-pro-menu-item-title">{initialState?.currentUser?.fio}</span><DownOutlined />
      </a>
    </Dropdown>
  );
};

export default BottomMenu;
