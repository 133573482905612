import { State, StateHandler } from '@/shared/utils';
import { inject, injectable } from 'inversify';
import { makeAutoObservable, reaction } from 'mobx';
import { logger } from '@workspace/4Z1.ts.utils';
import RequestHandler from '@/shared/utils/requestHandler';
import { ALLOWED_ORTOPHOTO_UPLOAD_FORMATS, Ortophoto, OrtophotoMeta } from '@/entities/ortophotos';
import { EmptyList, EmptyListStore } from '@/widgets/UploadDropzone/ui/UploadDropzone';
import { GalleryIcon } from '@workspace/4Z1.uikit.react';
import { isEqual, isNil } from 'lodash';
import { OrtophotoService } from '@/entities/ortophotos/model/ortophoto.service';
import { DiKeys } from '@/shared/di/global';

import { BaseStoreEffect, DeletionStoreEffects } from '@/shared/model';
import { ZalaType } from '@/entities/zalaType';
import { AcceptFileFormats } from '@/entities/fileTypes';
import { FlightChangesObservationService, FlightObservationListener } from '@/entities/flight';
import { FlightChangeEventsKeys, FlightEventsKeys, type FlightEventBus } from '@/entities/flight';
import { Disposer } from '@/shared/utils/disposer';

const log = logger('Store:Ortophoto');

interface OrtophotoForm {
  name: string;
}

export type OrtophotoStoreEffect = BaseStoreEffect & DeletionStoreEffects;

export interface InputOption {
  readonly label: string;
  readonly value: OrtophotoMeta;
}

export interface InputViewModel {
  readonly value: string;
  readonly disabled: boolean;
  readonly readonly?: boolean;
  readonly onInputChange?: (value: string) => void;
  readonly maxLength?: number;
}

export interface ActionsViewModel {
  readonly isConfirmModalOpen: boolean;
  readonly disabled: boolean;
  readonly active: boolean;
  readonly onChangesSave: () => void;
  readonly onChangesRevert: () => void;
  readonly onDeleteButtonClick: () => void;
  readonly onDeletionConfirm: () => void;
  readonly onDeletionCancel: () => void;
  readonly onCompareButtonClick: () => void;
  readonly onCompareClose: () => void;
}

export interface DropdownViewModel {
  readonly value: string;
  readonly options: readonly InputOption[];
  readonly onSelect: (value: OrtophotoMeta) => void;
  readonly visible: boolean;
  readonly loading: boolean;
}

export interface CompareeViewModel {
  readonly title: string;
  readonly visible: boolean;
  readonly loading: boolean;
}

export interface OrtophotoStoreState {
  readonly ortophoto?: Ortophoto;
  readonly compareeOrtophoto?: Ortophoto;
  readonly isWaitingForChanges?: boolean;
}

export interface OrtophotoStore {
  readonly mount: (flightId: string, effects?: OrtophotoStoreEffect) => void;
  readonly unmount: () => void;
  readonly onUploadingStarted: () => void;
  readonly saveChanges: () => Promise<void>;
  readonly revertChanges: () => void;
  readonly state: State<OrtophotoStoreState, unknown>;
  readonly isWaitingForChanges: boolean;
  /** View-models */
  readonly inputView: InputViewModel;
  readonly compareeInputView: InputViewModel;
  readonly dropdownView: DropdownViewModel;
  readonly compareeView: CompareeViewModel;
  readonly actionsView: ActionsViewModel;
  readonly hasOrtophoto: boolean;
  readonly acceptFiles: readonly AcceptFileFormats[];
  readonly uploadType: ZalaType;
}

const FORM_DEFAULT_VALUES: OrtophotoForm = {
  name: '',
} as const;

@injectable()
export class OrtophotoStoreImpl implements OrtophotoStore, EmptyListStore {
  /** Сервисы и провайдеры */
  private readonly ortophotoService: OrtophotoService;
  private readonly pollingService: FlightChangesObservationService;

  public readonly acceptFiles: AcceptFileFormats[] = [
    AcceptFileFormats.TIF,
    AcceptFileFormats.TIFF,
    AcceptFileFormats.ZIP,
  ];
  public readonly uploadType: ZalaType = ZalaType.Ortophoto;

  /** Переменные */
  private flightId: string | undefined = undefined;
  private form: OrtophotoForm = FORM_DEFAULT_VALUES;
  private defaultFormValues: OrtophotoForm = FORM_DEFAULT_VALUES;
  private ortophoto: Ortophoto | undefined = undefined;
  public isWaitingForChanges: boolean = false;

  /** Константы */
  private readonly maxOrtophotoNameLength = 255;

  private isCompareActive: boolean = false;
  private isConfirmModalOpen: boolean = false;
  private compareeOrtophoto: Ortophoto | undefined = undefined;

  /** Триггеры UI событий */
  private effects: OrtophotoStoreEffect | undefined = undefined;
  private reactions = [
    () =>
      reaction(
        () => this.hasChanges,
        (hasChanges) =>
          this.eventBus.emit(FlightEventsKeys.onChanged, {
            key: FlightChangeEventsKeys.onChildChanged,
            value: hasChanges,
          }),
      ),
  ];
  private disposer = new Disposer(this.reactions);

  private readonly updatesListener: FlightObservationListener;


  constructor(
    @inject(FlightChangesObservationService.diKey) flightUpdatesService: FlightChangesObservationService,
    @inject(DiKeys.ortophotoService) private readonly ortophotoService: OrtophotoService,
    @inject(DiKeys.flightCardEventBus) private readonly eventBus: FlightEventBus,
    private readonly requestHandler = new RequestHandler<Ortophoto | undefined>(),
    private readonly compareeHandler = new RequestHandler<Ortophoto | undefined>(),
    private readonly dropdownOrtophotosHandler = new RequestHandler<readonly InputOption[]>(),
    private readonly formUpdateHandler = new RequestHandler<void>(),
  ) {
    makeAutoObservable(this);

    const onOpfUpdated = () => {
      if (this.flightId === undefined) return;
      this.fetchOrtophotoByFlightId(this.flightId);
    }

    this.updatesListener = {
      onFlightUpdated: function (_: string, type: ZalaType): void {
        if (type === ZalaType.Ortophoto) {
          onOpfUpdated();
        }
      }
    };

    this.ortophotoService = ortophotoService;
    this.pollingService = flightUpdatesService;

    this.eventBus.on(FlightEventsKeys.onSave, (onlyArtifacts) => onlyArtifacts && this.saveChanges());
    this.eventBus.on(FlightEventsKeys.onCancel, () => this.revertChanges());
  }

  public mount(flightId: string, effects?: OrtophotoStoreEffect): void {
    this.disposer.attach();

    log.debug('Successfully mounted');
    this.pollingService.addListener(this.updatesListener);
    this.flightId = flightId;

    this.fetchOrtophotoByFlightId(this.flightId);

    this.effects = effects;
    this.flightId = flightId;
  }

  public unmount(): void {
    this.pollingService.removeListener(this.updatesListener);
    log.debug('Successfully unmounted');
    this.disposer.detach();
    this.resetForm();
  }

  private async deleteOrtophoto(): Promise<void> {
    this.isConfirmModalOpen = false;

    const flightId = this.flightId;
    const ortophoto = this.ortophoto;

    if (isNil(ortophoto) || isNil(flightId)) {
      return;
    }

    this.ortophotoService
      .deleteOrtophoto(ortophoto.id)
      .then(() => {
        this.fetchOrtophotoByFlightId(flightId);
        this.effects?.onDeleteSuccess();

        log.debug('Ortophoto successfully deleted', ortophoto);
      })
      .catch(error => {
        this.effects?.onDeleteError();

        log.error('Ortophoto deletion failed with error ::', error);
      });
  }

  private async openDeletionModal(): Promise<void> {
    this.isConfirmModalOpen = true;
  }

  public async saveChanges(): Promise<void> {
    log.debug('Saving changes', this.form);

    if (!StateHandler.isReady(this.state)) return;

    const ortophotoId = this.state.data.ortophoto?.id;

    if (isNil(ortophotoId)) return;

    if (isEqual(this.form, this.defaultFormValues)) {
      return;
    }

    return this.formUpdateHandler
      .handleRequest(() => this.ortophotoService.updateOrtophoto(ortophotoId, this.form))
      .then(() => {
        this.effects?.onRequestSuccess();
        this.defaultFormValues = { ...this.form };
      })
      .catch(() => this.effects?.onRequestError());
  }

  public revertChanges(): void {
    log.debug('Cancelling all changes', this.form);
    this.resetForm();
  }

  private fetchOrtophotoByFlightId(flightId: string): void {
    this.requestHandler
      .handleRequest(() => this.ortophotoService.fetchOrtophotoByFlightId(flightId))
      .then(data => this.onOrtophotoReceived(data));
  }

  private fetchCompareeFlights(ortophotoId: string): void {
    this.dropdownOrtophotosHandler.handleRequest(() => this.ortophotoService.fetchOrtophotoComparees(ortophotoId));
  }

  public get state(): State<OrtophotoStoreState, unknown> {
    const { isLoading, error, data } = this.requestHandler;

    if (isLoading) {
      return StateHandler.loading();
    }

    if (error) {
      return StateHandler.error(error ?? 'An error occured during component rendering');
    }

    if (data || isNil(error)) {
      return StateHandler.ready({
        ortophoto: data,
        compareeOrtophoto: this.compareeOrtophoto,
        isWaitingForChanges: this.isWaitingForChanges,
      });
    }

    log.error('An unknown component state met ::', data, error);

    return StateHandler.error('Couldn`t resolve the state of component');
  }

  public onUploadingStarted(): void {
    this.isWaitingForChanges = true;
    this.pollingService.waitingForChanges();
  }
  public get actionsView(): ActionsViewModel {
    return {
      isConfirmModalOpen: this.isConfirmModalOpen,
      disabled: !this.hasChanges || this.formUpdateHandler.isLoading,
      active: this.isCompareActive,
      onChangesRevert: () => this.revertChanges(),
      onChangesSave: () => this.saveChanges(),
      onDeleteButtonClick: () => this.openDeletionModal(),
      onDeletionCancel: () => this.onDeletionCancel(),
      onDeletionConfirm: () => this.deleteOrtophoto(),
      onCompareButtonClick: () => this.toggleCompareMode(),
      onCompareClose: () => this.disableCompareMode(),
    };
  }

  public get inputView(): InputViewModel {
    return {
      onInputChange: (value: string) => this.handleFieldChange('name', value),
      value: this.form.name,
      disabled: this.formUpdateHandler.isLoading,
      maxLength: this.maxOrtophotoNameLength,
    };
  }

  public get compareeInputView(): InputViewModel {
    return {
      value: this.compareeOrtophoto?.name ?? '',
      disabled: false,
      readonly: true,
    };
  }

  public get compareeView(): CompareeViewModel {
    return {
      title: this.compareeOrtophoto?.name ?? '',
      visible: !isNil(this.compareeOrtophoto),
      loading: this.compareeHandler.isLoading,
    };
  }

  public get dropdownView(): DropdownViewModel {
    const { data } = this.dropdownOrtophotosHandler;

    return {
      value: this.compareeOrtophoto?.name ?? '',
      options: data ?? [],
      visible: this.isCompareActive,
      onSelect: (ortophoto: OrtophotoMeta) => this.onCompareeSelect(ortophoto),
      loading: this.dropdownOrtophotosHandler.isLoading,
    };
  }

  public get emptyList(): EmptyList {
    return {
      icon: GalleryIcon.XLarge,
      textLocaleKey: 'ofp.noPreviousUploadFound',
      accept: ALLOWED_ORTOPHOTO_UPLOAD_FORMATS,
      onFilesSelected: files => console.log(files),
    };
  }

  public get hasOrtophoto(): boolean {
    return Boolean(this.ortophoto);
  }

  private enableCompareMode(): void {
    this.isCompareActive = !this.isCompareActive;

    if (this.flightId) {
      this.fetchCompareeFlights(this.flightId);
    }
  }

  private disableCompareMode(): void {
    this.isCompareActive = false;
    this.compareeOrtophoto = undefined;
  }

  private toggleCompareMode(): void {
    if (this.isCompareActive) {
      this.disableCompareMode();
      return;
    }

    this.enableCompareMode();
  }

  private onDeletionCancel(): void {
    this.isConfirmModalOpen = false;
  }

  private onCompareeSelect(ortophoto?: OrtophotoMeta): void {
    if (isNil(ortophoto)) {
      this.compareeOrtophoto = undefined;

      return;
    }

    this.compareeHandler
      .handleRequest(() => this.ortophotoService.fetchOrtophotoById(ortophoto.id))
      .then(ortophoto => {
        this.compareeOrtophoto = ortophoto;
      });
  }

  private onOrtophotoReceived(data: Ortophoto | undefined): void {
    if (data !== undefined) {
      this.isWaitingForChanges = false;
    }
    this.ortophoto = data;
    this.defaultFormValues = this.collectFormFromOrtophoto(data);
    this.resetForm();
  }

  // TODO - Реализовать FormHelper, чтобы не писать метод ниже каждый раз в приложении
  private handleFieldChange<Key extends keyof OrtophotoForm>(key: Key, value: OrtophotoForm[Key]): void {
    this.form[key] = value;
  }

  private resetForm(): void {
    this.form = { ...this.defaultFormValues };
  }

  private collectFormFromOrtophoto(ortophoto?: Ortophoto): OrtophotoForm {
    return {
      name: ortophoto?.name ?? '',
    };
  }

  private get hasChanges(): boolean {
    return !isEqual(this.form, this.defaultFormValues);
  }
}
