import './styles.scss';

import { dep, diInject } from '@/HOC';
import { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { InputViewModel, OrtophotoStore, OrtophotoStoreEffect, OrtophotoStoreState } from '../model/ortophoto.store';
import { DiKeys } from '@/shared/di/global';
import { Exception, Skeleton, Input, GalleryIcon, DeleteIcon, CloseIcon } from '@workspace/4Z1.uikit.react';
import { StateHandler } from '@/shared/utils';
import { useIntl } from 'react-intl';
import { isNil } from 'lodash';
import { OrtophotoViewer } from './OrtophotoViewer';
import OrtophotoActionsPanel from './OrtophotoActionsPanel';
import { message } from 'antd';
import { LoadingBlock } from '@workspace/4Z1.uikit.react';
import { UploadFilesFlight } from '@/features/UploadFiles';
import { ConfirmOrtophotoDeletion } from './OrtophotoConfirmDeletion';
import { DownloadOrtophoto } from '@/features/DownloadOrtophoto';
import { type Ortophoto } from '@/entities/ortophotos';

interface Props {
  readonly flightId: string;
  readonly store: OrtophotoStore;
}

const LoadingComponent = (): ReactNode => {
  return <Skeleton stretch loading />;
};

const ErrorComponent = (): ReactNode => {
  const intl = useIntl();

  return <Exception.Empty transparentBg>{intl.formatMessage({ id: 'EmptyTab' })}</Exception.Empty>;
};

const OrtophotoEditTitle = ({ viewModel }: { viewModel: InputViewModel }) => {
  return (
    <Input
      containerClassName='card-title'
      disabled={viewModel.disabled}
      maxLength={viewModel.maxLength}
      value={viewModel.value}
      readonly={viewModel.readonly}
      onChange={(value: string) => viewModel.onInputChange?.(value)}
    />
  );
};

const Ortophoto: FC<Props> = ({ flightId, store }) => {
  const intl = useIntl();

  const effects: OrtophotoStoreEffect = {
    onRequestError: () => message.error(intl.formatMessage({ id: 'dtoe.error.Submit' })),
    onRequestSuccess: () => message.success(intl.formatMessage({ id: 'SuccessfulSave' })),
    onDeleteError: () => message.error(intl.formatMessage({ id: 'ofp.delete.error' })),
    onDeleteSuccess: () => message.success(intl.formatMessage({ id: 'ofp.delete.success' })),
  };

  const renderDefaultActions = (ortophoto?: Ortophoto) => {
    if (isNil(ortophoto)) return [];

    return [
      {
        component: <DownloadOrtophoto ortophotoId={ortophoto.id} />,
      },
    ];
  };

  const renderViewerActions = (state: OrtophotoStoreState) => {
    return [
      ...renderDefaultActions(state.ortophoto),
      {
        icon: <DeleteIcon.Small />,
        onClick: () => store.actionsView.onDeleteButtonClick(),
      },
    ];
  };

  const renderCompareeActions = (state: OrtophotoStoreState) => {
    return [
      ...renderDefaultActions(state.compareeOrtophoto),
      {
        icon: <CloseIcon />,
        onClick: () => store.actionsView.onCompareClose(),
      },
    ];
  };

  const ReadyComponent = useCallback(
    ({ state }: { state: OrtophotoStoreState }) => {
      if (isNil(state.ortophoto)) {
        // access['zalaortophoto/upload'] ? <UploadDropzone /> : <EmptyComponent />
        // return <UploadDropzone />
        return state.isWaitingForChanges && isNil(state.ortophoto) ? (
          <div className="ortophoto__container">
            <OrtophotoActionsPanel />
            <LoadingBlock />
          </div>
        ) : (
          <div className='ortophoto__container'>
            <OrtophotoActionsPanel />
            <UploadFilesFlight
              flightId={flightId}
              fileType={store.uploadType}
              icon={<GalleryIcon.XLarge />}
              title={'ofp.noPreviousUploadFound'}
              accept={store.acceptFiles}
              onLoadStart={() => {
                store.onUploadingStarted();
              }}
            />
          </div>
        );
      }

      return (
        <div className='ortophoto-map__layout'>
          <OrtophotoActionsPanel />

          <div className='ortophoto-map__container'>
            <OrtophotoViewer
              titleComponent={<OrtophotoEditTitle viewModel={store.inputView} />}
              ortophoto={state.ortophoto}
              actions={renderViewerActions(state)}
            />
            {store.compareeView.visible && (
              <OrtophotoViewer
                id='comparant'
                titleComponent={<OrtophotoEditTitle viewModel={store.compareeInputView} />}
                ortophoto={state.compareeOrtophoto}
                loading={store.compareeView.loading}
                actions={renderCompareeActions(state)}
              />
            )}
          </div>

          <ConfirmOrtophotoDeletion viewModel={store.actionsView} />
        </div>
      );
    },
    [store.compareeView, store.inputView, store.compareeInputView, renderViewerActions, renderCompareeActions, store.actionsView],
  );

  const ortophotoState = useMemo(() => {
    return new StateHandler<OrtophotoStoreState, unknown>()
      .loading(() => <LoadingComponent />)
      .error(() => <ErrorComponent />)
      .ready(state => <ReadyComponent state={state} />);
  }, []);

  useEffect(() => {
    store.mount(flightId, effects);

    return () => {
      store.unmount();
    };
  }, [flightId]);

  return ortophotoState.render(store.state);
};

export default diInject(Ortophoto, {
  store: dep(DiKeys.ortophotoStore),
});
