import { useMapsQuery } from '@/.graphql/graphql';
import { type FC, useEffect } from 'react';
import { MapEngine} from '@/shared/map/model/interfaces';
import { implicitMapCheck, OSM_MAP_ID, MapZIndexLayer } from '@/shared/map';
import { useIntl } from 'react-intl';
import { MapsDocument } from '@/shared/map';
import BaseLayer from 'ol/layer/Base';
import { Layers } from '@/shared/map';
import { useCookies } from 'react-cookie';


interface Props {
  map?: MapEngine;
}

export const MapsLayer: FC<Props> = ({map = implicitMapCheck()}) => {

  const LAYER_NAME = Layers.Maps;

  const intl = useIntl();
  const [cookies] = useCookies(['layers']);

  // Получение данных с бэка
  const [queryMaps] = useMapsQuery();
  const data = queryMaps?.data?.maps ?? undefined;

  // Формирование основного слоя OSM
  const getOsmLayer = (): BaseLayer => {
    const osm = map.osmLayer;
    osm.set('map_id', OSM_MAP_ID);
    osm.set('title', intl.formatMessage({id: 'map.OSM'}));
    osm.set('zIndex', MapZIndexLayer.Map);
    osm.setVisible(cookies.layers.maps === OSM_MAP_ID || !cookies.layers.maps);
    return osm;
  }

  useEffect(() => {
    if (data === undefined) return;

    // очищаем слой, если появились новые данные
    map.removeLayerByName(LAYER_NAME);

    // подготовка базовых данных
    const maps: BaseLayer[] = [getOsmLayer()];

    // Формирование слоев пришедших с бэка
    data.forEach((mapLayer: MapsDocument) => maps.push(map.createMapLayer(mapLayer, cookies.layers.maps === mapLayer.id)));

    // Добавление всех слоев на карту
    map.addLayerGroups(maps, {name: LAYER_NAME});

    return () => {
      map.removeLayerByName(LAYER_NAME);
    }
  }, [data]);

  return (<></>)
}
