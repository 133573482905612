import { type FC, useEffect } from 'react';
import { MapPluginProps } from '@/shared/map/model/interfaces';
import { getOlMap, implicitMapCheck } from '@/shared/map';
import { group } from '@/components/Map/Layers';
import { dataVltTypes } from '@/components/VltTypes';
import { Flight, useViolationsQuery } from '@/.graphql/graphql';
import { useModel } from '@umijs/max';

interface Props extends MapPluginProps {
  readonly flight?: Flight;
}

export const ViolationsLayer: FC<Props> = ({
  flight,
  map = implicitMapCheck(),
}) => {
  const { initialState } = useModel('@@initialState');
  const initOptions = initialState?.currentOptions;
  const date_range_stream = initOptions?.date_range?.stream || initOptions?.date_range?.main || [];
  const vlt_types = dataVltTypes();

  const [violations, refreshVlt] = useViolationsQuery({
    variables: {
      flight_id: flight?.flight_id,
      date_range: date_range_stream?.join(' - '),
      route: JSON.stringify([]),
    },
  });

  useEffect(() => {
    refreshVlt();
  }, [date_range_stream]);

  return (
    <group.Violations
      map={getOlMap(map)}
      data={violations.data?.violations}
      vlt_types={vlt_types}
    />
  );
};
