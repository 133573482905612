import { logger } from '@workspace/4Z1.ts.utils';
import { cond, isArray, isFunction, stubTrue } from 'lodash';
import { IReactionDisposer } from 'mobx';

const log = logger('Disposer');

declare namespace Disposer {
  /**
   * Функция для добавления диспозеров
   */
  interface AttachFunction {
    (callback: Disposer.Callback): void;
    (callbacks: readonly Disposer.Callback[]): void;
  }

  /**
   * Интерфейс экземпляра Disposer
   */
  interface Instance {
    readonly attach: Disposer.AttachFunction;
    readonly detach: () => void;
  }

  /**
   * Тип диспозера (реакции из MobX)
   */
  type Callback = () => IReactionDisposer;

  /**
   * Счетчик подключений диспозеров
   */
  type Counter = number;

  /**
   * Функция отчистки слушателя
   */
  type Cleaner = IReactionDisposer;
}

export class Disposer implements Disposer.Instance {
  /**
   * Счетчик подключений
   * Если он достигает 0, все диспозеры освобождаются
   */
  private count: Disposer.Counter = 0;

  /**
   * Зарегистрированные диспозеры для управления ресурсами
   */
  private disposers: Disposer.Cleaner[] = [];

  /**
   * Создает экземпляр диспозера
   * @param {Disposer.Callback} callback - массив коллбеков или же один коллбек
   * @memberof Disposer
   */
  constructor(callback: Disposer.Callback);
  constructor(callbacks: readonly Disposer.Callback[]);
  constructor(private readonly callback: readonly Disposer.Callback[] | Disposer.Callback) {}

  /**
   * Подключает один или несколько диспозеров
   */
  public attach(): void {
    if (this.count > 0) {
      log.debug('Callback has already been attached! Aborting registration ::', this.callback, this.count);

      this.count += 1;

      return;
    }

    const register = cond([
      [isFunction, (callback) => this.registerCallback(callback)],
      [isArray, (callbacks) => this.registerCallbacks(callbacks)],
      [stubTrue, () => undefined],
    ]);

    if (register(this.callback) !== undefined) {
      this.count += 1;

      log.debug('New registry has been created for callbacks ::', this.callback);

      return;
    }

    log.error('An incorrect callback type provided ::', this.callback, typeof this.callback);
  }

  /**
   * Отключает диспозеры. Если счетчик достигает 0, освобождает ресурсы
   */
  public detach(): void {
    if (this.count > 0) {
      this.count -= 1;
      log.debug('Decreased counter due detach ::', this.disposers, this.count);
    }

    if (this.count === 0) {
      this.disposeAll();
    }
  }

  /**
   * Регистрирует один диспозер
   * @param callback - Диспозер для регистрации
   */
  private registerCallback(callback: Disposer.Callback): number {
    return this.disposers.push(callback());
  }

  /**
   * Регистрирует массив диспозеров
   * @param callbacks - Массив диспозеров для регистрации
   */
  private registerCallbacks(callbacks: readonly Disposer.Callback[]): number {
    return this.disposers.push(...callbacks.map(callback => callback()));
  }

  /**
   * Освобождает все зарегистрированные диспозеры и очищает их список
   */
  private disposeAll(): void {
    this.disposers.forEach(dispose => dispose());
    this.disposers = [];

    log.debug('All callbacks have been disposed ::', this.disposers, this.count);
  }
}
