export default {
    'tien.DisplayName': 'Имя',
    'tien.ServerName': 'Адрес',
    'tien.Status': 'Статус',
    'tien.AddServer': 'Добавить Сервер',
    'tien.Start': 'Старт',
    'tien.Stop': 'Стоп',
    'tien.Add': 'Добавить',
    'tien.Cancel': 'Отмена',
    'tien.Delete': 'Удалить',
    'tien.error.CannotStart': 'Не удалось запустить TiEn.',
    'tien.error.CannotStop': 'Не удалось остановить TiEn.',
    'tien.SuccessStart': 'TiEn запущен.',
    'tien.SuccessStop': 'TiEn остановлен.',
}