import { FlightChangeEvent, FlightChangeEventsKeys, FlightEventsKeys, type FlightEventBus } from '@/entities/flight';
import { DiKeys } from '@/shared/di/global';
import { inject, injectable, optional } from 'inversify';
import { makeAutoObservable } from 'mobx';

interface ButtonView {
  readonly disabled: boolean;
  readonly onClick: () => void;
}

export interface SaveCardChangesStore {
  readonly saveView: ButtonView;
  readonly cancelView: ButtonView;
}

@injectable()
export class SaveCardChangesStoreImpl implements SaveCardChangesStore {
  private changes = new Map<string, boolean>();

  constructor(
    /** 
     * Временный костыль, чтобы избежать циклической зависимости модулей
     * 
     * Необходимо избавиться от прямого использования EventBus'а внутри стора, предпочтительнее перенеся его в DI-модули
     */
    @inject(DiKeys.flightCardEventBus) private readonly eventBus: FlightEventBus,
    @inject(DiKeys.onSave) @optional() private readonly onSave?: (onlyArtifacts?: boolean) => Promise<void>,
    @inject(DiKeys.onCancel) @optional() private readonly onCancel?: () => void,
  ) {
    makeAutoObservable(this);

    this.eventBus.on(FlightEventsKeys.onChanged, (event) => this.onChangesReceived(event));
  }

  private onChangesReceived(event: FlightChangeEvent): void {
    if (event.value) {
      this.changes.set(event.key, event.value);

      return;
    }

    this.changes.delete(event.key);
  }

  private get disabled(): boolean {
    return this.changes.size === 0;
  }

  private get withArtifacts(): boolean {
    return this.changes.has(FlightChangeEventsKeys.onChildChanged);
  }

  private cleanAfterAction(callback: () => void): () => void {
    return () => {
      callback();

      /** 
       * Временный костыль
       * 
       * Из-за того, что кнопка сохранения изменений на карточке полёта работает для любых изменений на странице,
       * приоритет вызова колбэков срабатывания будет следующий:
       *  1. Сначала выполняется колбэк для всех изменений внутри таба (изменение имени вложения, например)
       *  2. Затем выполняется колбэк для главной сущности полёта (изменение воркфлоу, например)
       */
      if (this.withArtifacts) {
        this.changes.delete(FlightChangeEventsKeys.onChildChanged);

        return;
      }

      this.changes.delete(FlightChangeEventsKeys.onParentChanged);
    };
  }

  public get saveView(): ButtonView {
    return {
      disabled: this.disabled,
      onClick: this.cleanAfterAction(() => this.onSave?.(this.withArtifacts)),
    };
  }

  public get cancelView(): ButtonView {
    return {
      disabled: this.disabled,
      onClick: this.cleanAfterAction(() => this.onCancel?.()),
    };
  }
}
