import { Maps } from '@/shared/map';
import React, { FC } from 'react';
import { MapsLayer, MarkersLayer, RoutesLayer, TilesLayer } from '@/features/map';
import { AirSpaceLayer } from '@/features/map/layers';
import { ViolationsLayer } from '@/features/map/layers';
import { OfpLayer } from '@/features/map/layers';

export const FlightCard: FC = () => {
  return (
    <Maps.SimpleMapCard>

      <MapsLayer />
      <AirSpaceLayer />
      <RoutesLayer />
      <TilesLayer />
      <ViolationsLayer />
      <OfpLayer />
      <MarkersLayer />

    </Maps.SimpleMapCard>
  )
}
