import { FeaturesType } from '@/entities/features';
import { MapEngine } from '@/shared/map/model/interfaces';
import { head, isNil } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { MapBrowserEvent } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { Pixel } from 'ol/pixel';

interface HoverCursorPosition {
  readonly x: number;
  readonly y: number;
}

export interface TooltipActions {
  readonly updateContent: (lines: readonly string[]) => void;
  readonly show: (position: HoverCursorPosition, elementCount: number) => void;
  readonly hide: () => void;
}

export class TooltipOverlayStore {
  private actions: TooltipActions | undefined = undefined;
  private pendingFrame: number | undefined = undefined; // Хранит идентификатор активного requestAnimationFrame

  constructor(private readonly map: MapEngine) {
    makeAutoObservable(this);
  }

  public mount(actions: TooltipActions): void {
    this.actions = actions;
    this.map.onPointerMove(this.handlePointerMove);
  }

  private handlePointerMove = (event: MapBrowserEvent<UIEvent>): void => {
    if (this.pendingFrame) {
      return;
    }

    this.pendingFrame = requestAnimationFrame(() => {
      this.pendingFrame = undefined;
      const names = this.collectFeatureNames(event.pixel);
      this.updateTooltipVisibility(names, event);
    });
  };

  private collectFeatureNames(pixel: Pixel): readonly string[] {
    const names: string[] = [];

    this.map.forEachFeatureAtPixel(pixel, feature => {
      const type: FeaturesType | undefined = feature.get('type');
      const cluster = feature.get('features');

      if (type === FeaturesType.CLUSTER) {
        const featureNames = this.collectSingleFeatureName(feature);

        names.push(...featureNames);

        return;
      }

      const featureNames = cluster ? this.collectClusterNames(cluster) : this.collectSingleFeatureName(feature);

      names.push(...featureNames);
    });

    return names;
  }

  private collectClusterNames(cluster: readonly FeatureLike[]): readonly string[] {
    return cluster
      .map((feature: FeatureLike) => head(this.collectSingleFeatureName(feature)))
      .filter((name) => !isNil(name));
  }

  private collectSingleFeatureName(feature: FeatureLike): readonly string[] {
    const featureName: string | undefined = feature.get('name');
    const featurePrefix: string | undefined = feature.get('prefix');
    const featureText: string | undefined = feature.get('text');

    const rawText = featureText ?? featureName;
    const rawPrefix = `<span class="tooltip-prefix">${featurePrefix}</span></br>`;

    const text = featurePrefix ? `${rawPrefix}${rawText}` : `${rawText}`;

    return rawText ? [text] : [];
  }

  private updateTooltipVisibility(names: readonly string[], event: MapBrowserEvent<UIEvent>): void {
    if (!this.actions) return;

    if (names.length > 0) {
      this.actions.updateContent(names);
      const { layerX, layerY } = event.originalEvent as MouseEvent;

      this.actions.show(
        {
          x: layerX,
          y: layerY,
        },
        names.length,
      );
    } else {
      this.actions.hide();
    }
  }

  public unmount(): void {
    this.actions = undefined;
    this.map.offPointerMove(this.handlePointerMove);

    // Отменяем активный requestAnimationFrame, если он существует
    if (this.pendingFrame) {
      cancelAnimationFrame(this.pendingFrame);
      this.pendingFrame = undefined;
    }
  }
}
