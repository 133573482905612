export default {
  'menu.home': 'Главная',
  'menu.flights': 'Полёты',
  'menu.downloads': 'Загрузки',
  'menu.photos': 'Архив фото',
  'menu.videos': 'Видео',
  'menu.ofps': 'Ортофотопланы',
  'menu.ofp_swipe': 'Ортофотопланы.Swipe',
  'menu.ofp_compare': 'Ортофотопланы.Сравнение',
  'menu.ofp_compare_cd': 'Ортофотопланы.ГеоДинамика',
  'menu.admin': 'Admin',
  'menu.Profile': 'Профиль',
  'menu.Massload': 'Загрузка',
  'menu.login': 'Вход',
  'menu.logout': 'Выход',
  'menu.online': 'Онлайн трансляции',
  'menu.gazes': 'Газоанализатор',
  'menu.Statistics': 'Статистика',
  'menu.Routes': 'Маршруты',
  'menu.Tiles': 'Слои',
  'menu.Admin menu': 'Администрирование',
  'menu.orders': 'Заявки',
  'menu.reports': 'Отчёты',
  'menu.cdreports': 'ГеоДинамика',
  'menu.UserAccess': 'Доступ пользователей',
  'menu.Heatmap': 'Тепловая карта',
  'menu.online-dashboard': 'Мультистрим',
  'menu.Assets': 'Объекты',
  'menu.AssetEdit': 'Редактирование объекта',
  'menu.Download': 'Загрузка карт',
  'menu.TiEnOrchestrator': 'Оркестровка TiEn',
};
