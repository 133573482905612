import { isNumber } from 'lodash';

/**
 * Преобразовывает строку, состоящую из элементов, разделенных запятой, к массиву чисел
 *
 * @example
 *
 * parseNumberArrayFromString("1,2,3") // вернет [1,2,3];
 * parseNumberArrayFromString("1") // вернет [1];
 * parseNumberArrayFromString("notnumber,1,2,3") // выбросит исключение;
 */
export const parseNumberArrayFromString = (value: string): readonly number[] => {
  return value.split(',').map(item => {
    const parsedItem = Number(item);

    if (isNumber(parsedItem)) return parsedItem;

    throw new Error(`Cannot cast literal symbol to number - ${item} casted to ${parsedItem}`);
  });
};
