import { Base } from './Base';
import { Gas } from './Gas';
import { Onlines } from './Onlines';
import {  SimpleMapCard } from './SimpleMapCard';
import { FlightCard } from './FlightCard';

/** Библиотека шаблонов карт */
let Maps = {
  Base: Base,
  Onlines: Onlines,
  Gas: Gas,
  SimpleMapCard: SimpleMapCard,
  FlightCard: FlightCard,
}

export { Maps }
