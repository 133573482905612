import { FC, useEffect, useState } from 'react';
import { implicitMapCheck, Layers, MapPluginProps, MapZIndexLayer } from '@/shared/map';
import { Coordinates } from '@workspace/4Z1.ts.utils';
import { isNil } from 'lodash';
import { Point } from 'ol/geom';
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import { getWaypointStyle, WaypointType } from '@/entities/assetTrack';

interface Props extends MapPluginProps {
  readonly points: Coordinates[] | undefined;
}

export const PointsLayer: FC<Props> = ({points, map = implicitMapCheck()}) => {
  const LAYER_NAME = Layers.Points;

  const [source] = useState(new VectorSource());
  const [layer] = useState(new VectorLayer());

  useEffect(() => {

    source.clear();

    if (isNil(points)) return;

    const features = points.map(point =>
      new Feature({geometry: new Point(fromLonLat([point.lon, point.lat]))}));

    source.addFeatures(features);

    layer.setStyle(getWaypointStyle(WaypointType.Idle))

    layer.setSource(source);
  }, [points]);

  useEffect(() => {
    layer.set('name', LAYER_NAME);
    layer.set('zIndex', MapZIndexLayer.Points);
    map.addLayer(layer);

    return () => map.removeLayerByName(LAYER_NAME);
  }, [map]);
  return <></>
}
