import { FC, useEffect, useState } from 'react';
import { implicitMapCheck, Layers, MapPluginProps, MapZIndexLayer } from '@/shared/map';
import { Coordinates } from '@workspace/4Z1.ts.utils';
import { isNil } from 'lodash';
import { Point } from 'ol/geom';
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import { getWaypointStyle, WaypointType } from '@/entities/assetTrack';


interface Props extends MapPluginProps {
  readonly coordinates: Coordinates | undefined;
  readonly focusOnPoint?: boolean;
}



export const SinglePointLayer: FC<Props> = ({coordinates, map = implicitMapCheck(), focusOnPoint = false}) => {
  const LAYER_NAME = Layers.SinglePoint;

  const [point] = useState(new Point([]));
  const [feature] = useState(new Feature());
  const [source] = useState(new VectorSource());
  const [layer] = useState(new VectorLayer());

  useEffect(() => {

    source.clear();

    if (isNil(coordinates)) return;

    if (focusOnPoint) {
      map.setCenter(coordinates);
    }

    point.setCoordinates(fromLonLat([coordinates.lon, coordinates.lat]));

    feature.setGeometry(point);
    feature.setStyle(getWaypointStyle(WaypointType.Highlight));

    source.addFeature(feature);

    layer.setSource(source);
  }, [coordinates, focusOnPoint]);

  useEffect(() => {
    layer.set('name', LAYER_NAME);
    layer.set('zIndex', MapZIndexLayer.SinglePoint);
    map.addLayer(layer);

    return () => map.removeLayerByName(LAYER_NAME);
  }, [map]);
  return <></>
}
