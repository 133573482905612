import { inject, injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';
import { FlightWorkflowStatus } from './interfaces';
import { Flight } from '../api/flight.api';
import { createFlightStatusesApi } from '../api/flightStatuses.api';
import { isEmpty } from 'lodash';
import { LocalizationService } from '@/shared/services/localization/localization.service';
import { NOT_PUBLIC_FLIGHTS_STATUSES_IDS } from '@/entities/FlightListServices/utils';

export interface FlightWorkflowStatusForFlight extends FlightWorkflowStatus {
  readonly condition: 'wait' | 'complete';
}

// Хардкод статусов с бэека
const NEW_FLIGHT_STATUS = '1';
const DRAFT_STATUS = '2';

@injectable()
export class FlightWorkflowService {

  public static readonly diKey = Symbol.for('FlightWorkflowService');

  private values: readonly FlightWorkflowStatus[] = [];

  constructor(
    @inject(LocalizationService.diKey) private readonly localizationService: LocalizationService,
    private readonly api = createFlightStatusesApi(),
  ) {
    makeAutoObservable(this);
  };

  /**
   * Принудительный (без учета кэширования) запрос списка статусов полета.
   * После успешного запроса - данные закэшируются до следующего явного вызова этого метода.
   */
  public fetchStatuses(): Promise<readonly FlightWorkflowStatus[]> {
    return this.api.fetchStatuses().then(data => {
      this.values = this.localizeTitles(data);
      return this.values;
    });
  }

  private localizeTitles(data: readonly FlightWorkflowStatus[]): readonly FlightWorkflowStatus[] {
    const intl = this.localizationService.intl;
    return data.map(item => ({
        ...item,
        name: intl.formatMessage({ id: `flight.workflow.status.${item.id}`, defaultMessage: item.name }),
    }));
  }

  public get statusList(): readonly FlightWorkflowStatus[] {
    this.checkCache();
    return this.values;
  }

  public get publicStatuses(): readonly FlightWorkflowStatus[] {
    this.checkCache();

    return this.values
      .filter((status) => !NOT_PUBLIC_FLIGHTS_STATUSES_IDS.includes(Number(status.id)))
  }

  public getStatusForFlight(flight: Flight): readonly FlightWorkflowStatusForFlight[] {
    this.checkCache();
    return this.values
      .filter(status => status.id !== NEW_FLIGHT_STATUS) // Берем все кроме "Новый полет", его на фронте не отображаем
      .map(status => ({ ...status, condition: status.id <= flight.workflowStatus ? 'complete' : 'wait'}))
  }

  public getCurrentStatus(status: string) {
    if (status === NEW_FLIGHT_STATUS) {
      return DRAFT_STATUS;
    }

    return status;
  }

  public isNewFlight(flight: Flight): boolean {
    return flight.workflowStatus === NEW_FLIGHT_STATUS;
  }

  public checkCache() {
    if (isEmpty(this.values)) {
      this.fetchStatuses();
    }
  }
}
