import { compact } from 'lodash';
import { FlightTypes } from '../flight/model';

export const flightTypesByString: Record<string, FlightTypes> = {
  gases: FlightTypes.Gaz,
  videos: FlightTypes.Video,
  photos: FlightTypes.Photo,
  violations: FlightTypes.Violation,
} as const;

export const getFlightTypeId = (type: string): FlightTypes | undefined => {
  return flightTypesByString[type] ?? undefined;
};

export const getFlightTypesIds = (types: readonly string[] = []): readonly FlightTypes[] => {
  return compact(types.map(type => getFlightTypeId(type)));
};

/** Статусы новых полётов */
const NEW_FLIGHT_STATUS_ID: number = 1;

/** Статусы полётов, скрываемых со списка полётов */
export const NOT_PUBLIC_FLIGHTS_STATUSES_IDS: readonly number[] = [
  NEW_FLIGHT_STATUS_ID,
] as const;