import { implicitMapCheck, MapPluginProps } from '@/shared/map';
import { FC } from 'react';
import KML from 'ol/format/KML';
import { ClusterLayer } from '../../clusterLayer';
import { useIntl } from 'react-intl';
import { TooltipTextFormatter } from '../../clusterLayer/ui/ClusterLayer';

interface Props extends MapPluginProps {
  readonly id: string;
  readonly file: string;
}

export const ClusterKmlLayer: FC<Props> = ({ map = implicitMapCheck(), file, id }) => {
  const intl = useIntl();

  const heatLayerSource = {
    url: file,
    format: new KML({
      extractStyles: false,
    }),
  };

  const tooltipTextFormatter: TooltipTextFormatter = (maxFeature) => {
    return {
      text: intl.formatMessage({ id: 'gasConcentration' }),
      prefix: intl.formatMessage({ id: 'gas' }, { count: maxFeature.get('name') }),
    };
  };

  return <ClusterLayer tooltipTextFormatter={tooltipTextFormatter} layerId={id} map={map} source={heatLayerSource} />;
};
