export default {
  'flight.name': 'Title',
  'flight.routes': 'All routes',
  'flight.users': 'All users',
  'flight.showAll': 'Show all',
  'flight.edit': 'Edit flight',
  'flight.NewFlight': 'New flight',
  'flight.list.empty':
    'There are currently no media attachments on this page',
  'flight.empty-uploads': 'No uploads found for this flight',
  'flight.users.2': 'Users',
  'flight.types': 'Types',

  'flight.list.suggest':
    'You can view other attachments',

  'flight.delete.confirm.title': 'Delete {title}',
  'flight.delete.confirm.body': 'Are you sure you want to delete 1 object?',
  'flight.flights_delete_error': 'An error occured during deletion. Try again!',

  'flight.photo-viewer.title': 'Photo {title}',
  'flight.video-viewer.title': 'Video {title} ',

  'flight.redirect': 'open',
  'flight.downloadKmlTooltip': 'Download KML',
  'flight.missingKmlTooltip': 'KML is missing',

  'flight.flights_update_success': 'Update success',
  'flight.flights_update_error': 'Update error',
  'flight.empty_new_flight': 'New flight cannot be empty',

  'flight.NO_FLIGHT': 'Flight not found',
  'flight.Edit_Name_Flight': 'Edit flight\'s name',
  'flight.flights_delete_success': 'Flight deleted',

  'flight.readMore': 'Read more',
  'flight.redirectToPage': 'Go to flight page',
};
