export default {
    'tien.DisplayName': 'Display Name',
    'tien.ServerName': 'Address',
    'tien.Status': 'Status',
    'tien.AddServer': 'Add Server',
    'tien.Start': 'Start',
    'tien.Stop': 'Stop',
    'tien.Add': 'Add',
    'tien.Cancel': 'Cancel',
    'tien.Delete': 'Delete',
    'tien.error.CannotStart': 'TiEn start failed.',
    'tien.error.CannotStop': 'TiEn stop failed.',
    'tien.SuccessStart': 'TiEn started.',
    'tien.SuccessStop': 'TiEn stopped.',
}