
import { Attachment, AttachmentTypes, GasAttachment, PhotoAttachment, VideoAttachment } from '@/entities/attachment';
import { makeAutoObservable } from 'mobx';
import { createFlightAttachmentsApi } from '@/entities/attachment';
import { injectable } from 'inversify';
import { State, StateHandler } from '@/shared/utils';
import RequestHandler from '@/shared/utils/requestHandler';
import { isNil } from 'lodash';

interface AttachmentsServiceState {
  readonly attachments: readonly Attachment[];
}

@injectable()
export class AttachmentsService {
  public static readonly diKey = Symbol.for('AttachmentsService');
  private _selectedAttachments: Attachment[] = [];
  // TODO - Переписать массив загруженных файлов, на Set

  private _attachments: readonly Attachment[] = [];

  constructor(
    private readonly api = createFlightAttachmentsApi(),
    private readonly requestHandler = new RequestHandler<readonly Attachment[]>(),
  ) {
    makeAutoObservable(this);
  }

  public fetchAttachments(flightId: string): void {
    this.requestHandler
      .handleRequest(() => this.api.fetchAttachments(flightId))
      .then(response => this.setFetchedAttachments(response));
  }

  public fetchGases(flightId: string): Promise<readonly GasAttachment[]> {
    return this.api.fetchGasesByFlight(flightId);
  }

  /**
   * TODO - Избавиться от стейта на уровне сервиса
  */
  public get state(): State<AttachmentsServiceState, unknown> {
    const { isLoading, error } = this.requestHandler;

    if (isLoading) {
      return StateHandler.loading();
    }

    if (!isNil(error)) {
      return StateHandler.error(this.requestHandler.error);
    }

    if (!isLoading && this.attachments) {
      return StateHandler.ready({
        attachments: this.attachments,
      })
    }

    return StateHandler.loading();
  }

  public get selectedAttachments(): readonly Attachment[] {
    return this._selectedAttachments;
  }

  public get attachments(): readonly Attachment[] {
    return this._attachments;
  }

  public get photos(): readonly PhotoAttachment[] {
    return this._attachments.filter(attachment => attachment.type === AttachmentTypes.Photo);
  }
  public get videos(): readonly VideoAttachment[] {
    return this._attachments.filter(attachment => attachment.type === AttachmentTypes.Video);
  }

  private setFetchedAttachments(data?: readonly Attachment[]): void {
    if (data === undefined) return; 
    
    this._attachments = data;
  }

  public get selectedAttachmentsCount(): number {
    return this._selectedAttachments.length;
  }
}
