import { dep, diInject } from '@/HOC';
import { FC } from 'react';
import { SaveCardChangesStore } from '../model/saveCardChanges.store';
import { Button, CloseIcon, SaveIcon } from '@workspace/4Z1.uikit.react';
import { DiKeys } from '@/shared/di/global';
import { useIntl } from 'react-intl';
import './styles.scss';

interface Props {
  readonly store: SaveCardChangesStore;
}

const SaveCardChanges: FC<Props> = ({ store }) => {
  const intl = useIntl();
  return (
    <div className='saveCardChanges'>
      <Button
        type='accent'
        iconPosition='left'
        icon={<SaveIcon.Small />}
        disabled={store.saveView.disabled}
        label={intl.formatMessage({ id: 'button.Save' })}
        onClick={() => store.saveView.onClick()}
      />
      <Button
        type='badge'
        iconPosition='left'
        icon={<CloseIcon.Small />}
        disabled={store.cancelView.disabled}
        label={intl.formatMessage({ id: 'button.Cancel' })}
        onClick={() => store.cancelView.onClick()}
      />
    </div>
  );
};

export default diInject(SaveCardChanges, {
  store: dep(DiKeys.saveCardChangesStore),
});
