import { ZalaType } from '@/entities/zalaType';

export enum UploadHistoryFileStatuses {
  Unknown = 'Unknown',
  Finished = 'Finished',
  Processing = 'Processing',
  Loading = 'Loading',
  Error = 'Error',
  Deleted = 'Deleted',
  InternalServerError = 'InternalServerError',
  Preprocessing = 'Preprocessing',
  Denied = 'Denied',
}

export interface UploadHistoryFile {
  readonly id: string;
  readonly fileName: string;
  readonly fileSize: number;
  readonly progress?: number;
  readonly uploadedSize?: number;
  readonly flightId: string;
  readonly zalaFileType: ZalaType;
  readonly status: UploadHistoryFileStatuses;
  readonly createdAt: string;
}

export type SortableFileProperties = Pick<
  UploadHistoryFile,
  'zalaFileType' | 'fileName' | 'fileSize' | 'createdAt' | 'status' | 'flightId'
>;
