import { Ortophoto } from '@/entities/ortophotos';
import { HoldScalePlugin } from '@/features/map/holdScalePlugin';
import { OrtophotoLayer } from '@/features/map/layers/ortophotoLayer';
import { FLIGHT_CARD_LAYERS, Maps } from '@/shared/map';
import { Button, Skeleton, CardWithActions } from '@workspace/4Z1.uikit.react';
import { observer } from 'mobx-react';
import { FC, ReactNode, useRef } from 'react';
import { AirSpaceLayer } from '@/features/map/layers';
import { ViolationsLayer } from '@/features/map/layers';

const SHARED_VIEW_KEY = 'compare';

interface ViewerActionButton {
  readonly icon?: ReactNode;
  readonly onClick?: () => void;
  readonly component?: ReactNode;
}

interface Props {
  readonly id?: string;
  readonly ortophoto?: Ortophoto;
  readonly titleComponent?: JSX.Element;
  readonly title?: string;
  readonly actions?: readonly ViewerActionButton[];
  readonly loading?: boolean;
}

const renderAction = ({ icon, onClick, component }: ViewerActionButton): ReactNode => {
  return component ?? <Button onlyIcon type='badge' size='small' icon={icon} onClick={onClick} />;
};

export const OrtophotoViewer: FC<Props> = observer(
  ({ id, ortophoto, title, titleComponent, actions = [], loading = false }) => {
    const toolsPanelRef = useRef<HTMLDivElement>(null);

    if (loading) {
      return <Skeleton stretch loading />;
    }

    return (
      <CardWithActions titleComponent={titleComponent} title={title} end={actions.map(renderAction)}>
        <div className='ortophoto-map'>
          <div ref={toolsPanelRef} className='map-tools header fixed only-right' />
          <Maps.Base id={id} mapTools={toolsPanelRef} sharedViewMapId={SHARED_VIEW_KEY} layerSortingTemplate={[...FLIGHT_CARD_LAYERS]}>
            <HoldScalePlugin target={toolsPanelRef} />
            <OrtophotoLayer data={ortophoto} />
            <AirSpaceLayer />
            <ViolationsLayer />
          </Maps.Base>
        </div>
      </CardWithActions>
    );
  },
);
