import { FC, useEffect, useState } from 'react';
import { implicitMapCheck, Layers, MapPluginProps, MapZIndexLayer, BASE_PADDING_FIT_MAP } from '@/shared/map';
import VectorLayer from 'ol/layer/Vector';
import { isEmpty } from 'lodash';
import { fromLonLat } from 'ol/proj';
import { LineString } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import { TrackData } from '@/entities/flight';
import { StyleLike } from 'ol/style/Style';

interface Props extends MapPluginProps {
  readonly track: TrackData | undefined;
  readonly style?: StyleLike;
}


export const TrackLayer: FC<Props> = ({ track, map = implicitMapCheck(), style }) => {
  const [layer] = useState(new VectorLayer());
  const [source] = useState(new VectorSource());

  const LAYER_NAME = Layers.Track;

  useEffect(() => {
    source.clear();

    // Если трека нет, ничего н отображаем
    if (isEmpty(track)) {
      return;
    }

    const routeCoordinates = track.coordinates.map(coord => fromLonLat(coord));

    const routeLine = new LineString(routeCoordinates);
    const feature = new Feature(routeLine);

    if(style) {
      feature.setStyle(style);
    }

    source.addFeatures([feature]);
    layer.setSource(source);

    map.fitMapToFeature(feature, BASE_PADDING_FIT_MAP);
  }, [track]);

  useEffect(() => {
    layer.set('name', LAYER_NAME);
    layer.set('zIndex', MapZIndexLayer.Track);

    map.addLayer(layer);

    return () => map.removeLayerByName(LAYER_NAME);
  }, [map]);

  return <></>;
}
