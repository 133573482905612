export const DiKeys = {
  userOptions: Symbol.for('userOptions'),
  setUploadingCallbackOnMount: Symbol.for('onMount'),
  onTabUnmount: Symbol.for('onTabUnmount'),
  assetFilter: Symbol.for('assetFilter'),
  onDeviceRevoke: Symbol.for('onDeviceRevoke'),
  assetOnMap: Symbol.for('assetOnMap'),
  onAssetDirectionChange: Symbol.for('onAssetDirectionChange'),
  onAssetCoordinatesChange: Symbol.for('onAssetCoordinatesChange'),
  showVideoStream: Symbol.for('showVideoStream'),
  flightOnMap: Symbol.for('flightOnMap'),
  updateGlobalDateRange: Symbol.for('updateGlobalDateRange'),
  selectedAssetOnMap: Symbol.for('selectedAssetOnMap'),
  LocaleStore: Symbol.for('LocaleStore'),
  DownloadFlight: Symbol.for('DownloadFlight'),
  flightRedirects: Symbol.for('FlightRedirects'),
  gasAnalyserStore: Symbol.for('GasAnalyserStore'),
  mediaAttachmentsProvider: Symbol.for('MediaAttachmentsProvider'),
  flightTelemetryStore: Symbol.for('FlightTelemetryStore'),
  photosStore: Symbol.for('PhotosStore'),
  ortophotoStore: Symbol.for('Ortophoto'),
  videosStore: Symbol.for('VideosStore'),
  downloadHistoryStore: Symbol.for('DownloadHistoryStore'),
  emptyListStore: Symbol.for('EmptyListStore'),
  ortophotoService: Symbol.for('OrtophotoService'),
  flightProvider: Symbol.for('FlightProvider'),
  gasProvider: Symbol.for('GasProvider'),
  onIncidentViewerClosed: Symbol.for('OnIncidentViewerClosed'),
  onSelectedIncident: Symbol.for('onSelectedIncident'),
  onSelectedTableElement: Symbol.for('onSelectedTableElement'),
  formHasChange: Symbol.for('formHasChange'),
  pollingService: Symbol.for('PollingService'),
  setIncidentCoordinates: Symbol.for('setIncidentCoordinates'),
  photosProvider: Symbol.for('photosProvider'),
  photosService: Symbol.for('ImageTilesService'),
  videosService: Symbol.for('VideosService'),
  deleteAttachmentsStore: Symbol.for('DeleteAttachmentsStore'),
  deleteViewerAttachmentStore: Symbol.for('DeleteViewerAttachmentStore'),
  selectedAttachmentsProvider: Symbol.for('SelectedAttachmentsProvider'),
  onAttachmentsDelete: Symbol.for('onAttachmentsDelete'),
  onFlightChange: Symbol.for('onFlightChange'),
  downloadOrtophotoStore: Symbol.for('DownloadOrtophotoStore'),
  onAttachmentsDownload: Symbol.for('onAttachmentsDownload'),
  onViewerAttachmentDelete: Symbol.for('onViewerAttachmentDelete'),
  viewerAttachmentProvider: Symbol.for('ViewerAttachmentProvider'),
  hoveredIncident: Symbol.for('hoveredIncident'),
  flightListItem: Symbol.for('flightListItem'),
  flightItemFromFlight: Symbol.for('flightItemFromFlight'),
  onItemHover: Symbol.for('onItemHover'),
  setHoveredMapPoint: Symbol.for('setHoveredMapPoint'),
  setMapTrack: Symbol.for('setMapTrack'),
  setMapPoint: Symbol.for('setMapPoint'),
  setMapPoints: Symbol.for('setMapPoints'),
  onSave: Symbol.for('onSave'),
  onCancel: Symbol.for('onCancel'),
  onCardChange: Symbol.for('onCardChange'),
  saveCardChangesStore: Symbol.for('SaveCardChangesStore'),
  flightCardEventBus: Symbol.for('flightCardEventBus'),

  flightCard: {
    onVideoTrackSeek: Symbol.for('onVideoTrackSeek'),
    onVideoTimeChange: Symbol.for('onVideoTimeChange'),
    onViewtimeParamsChange: Symbol.for('onViewtimeParamsChange'),
    onWorkflowStatusChanged: Symbol.for('onWorkflowStatusChanged'),
  } as const,
  createFlight: Symbol.for('createFlight'),
  api: {
    massload: Symbol.for('massload'),
    usbupload: Symbol.for('usbupload'),
  } as const,
} as const;
