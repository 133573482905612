import {
  UpdateFlightDocument,
  UpdateFlightMutationVariables,
  FlightDocument,
  FlightQuery as FlightQueryResult,
  FlightQueryVariables,
  Flight as OldFlight,
  FlightNameDocument,
  FlightNameQuery as FlightNameResponse,
} from '@/.graphql/graphql';
import { GraphQlApi, REQUEST_POLICY_NETWORK } from '@/shared/api/graphql';
import { FlightStatuses, TrackData, TrackSource } from '../model/interfaces';
import urlJoin from 'url-join';
import { isNil } from 'lodash';
import { createRestApi, RestApi } from '@/shared/api/rest';
import { getUserId } from '@/shared/utils/getUserId';
import { Dates, logger } from '@workspace/4Z1.ts.utils';
import { message } from 'antd';
import { Formats } from '@/entities/fileTypes';
import { emulateDownload } from '@/shared/utils/downloadFile';

const log = logger('FL:API');

export interface DownloadKmlParams {
  readonly flight_id?: string;
  readonly project_id: string;
  readonly flight_name?: string;
  readonly id?: string;
  readonly text?: string;
}

// TODO нужно вынести ее куда-то в более общее место. ее можно использовать не только для полетов
/**
 * Любая сущность внутри полета, которая обладает айди и названием
 */
export interface FlightItem {
  readonly id: string,
  readonly title: string,
}

export interface FlightCounters {
  readonly videoCount?: number,
  readonly photoCount?: number,
  readonly incidentCount?: number,
  readonly gasCount?: number,
  readonly ofpCount?: number,
}

// TODO нужно собрать нормальную сущность полета и перенести ее из апишки в модель.
export interface FlightExt extends FlightCounters {
  readonly flight: Flight,
  readonly availableRoutes: readonly FlightItem[],
  readonly crew: readonly FlightItem[],
  readonly crewNumber?: string,
}

// TODO переименовать? это не весь Flight - это лишь базовая информация по нему
export interface Flight {
  readonly flightId: number;
  readonly name: string;
  readonly startedAt: Date;
  readonly finishedAt: Date;
  readonly createdAt: Date | undefined;
  readonly updatedAt: Date | undefined;
  readonly status: number;
  readonly workflowStatus: string;
  readonly projectId: number;
  readonly geometry: string;
  readonly type: number;
  readonly authorId: number;
  readonly routeId: null | number;
  readonly ftpStatus: number;
  readonly color: string;
  readonly flightNumber: string;
}

export interface FlightApi {
  create: () => Promise<string>;
  flightName: (flightId: string) => Promise<string>;
  fetchFlight: (flightId: string) => Promise<OldFlight | undefined>;
  getFlightById: (flightId: string) => Promise<FlightExt | undefined>;
  getFlightAvailableRoutes: () => Promise<FlightExt | undefined>;
  getTrack: (flightId: string, trackSource: TrackSource) => Promise<TrackData>;
  /**
   * Сохраняет КАКИЕ-ТО (т.е. НЕ ВСЕ) данные о полете. Таков путь (и наша апишка :))
   */
  saveFlightData: (
    data: Flight,
    crew: readonly { id: string, title: string }[],
    crewNumber: string,
    workflowStatus?: string,
  ) => Promise<Flight | undefined>;
  deleteFlight: (flightId: string) => Promise<void>;
  downloadKmlAttachments: (
    params: DownloadKmlParams,
  ) => Promise<void>;
  downloadFlight: (
    flightId: string,
  ) => Promise<void>;
  uploadRoutes: (formData: File) => Promise<void>;
  downloadFlightTelemetry: (flightId: string, projectId: string, type?: Formats) => Promise<void>;
}


/*
  @deprecate устарело использовать FlightApiImpl
*/
class FlightImplApi implements FlightApi {
  private static readonly API_BASE = API;

  private static readonly PATHS = {
    flights: '/flights',
    download: '/download',
  } as const;

  private readonly clientGraphQl = new GraphQlApi();
  private readonly clientRest: RestApi = createRestApi();

  public async getFlightById(flightId: string): Promise<FlightExt | undefined> {
    const path = urlJoin([API, '/flights', `${flightId}/card`]);
    const resp = await this.clientRest.get(path, {userId: getUserId()});
    const routesFlight = await this.getFlightAvailableRoutes();

    const data = resp?.data;

    if (isNil(data)) {
      return undefined;
    }

    const flightData = data.flight;

    const flight: Flight = {
      ...flightData,
      workflowStatus: String(flightData.workflowStatus),
      startedAt: flightData.startedAt > 0 ? Dates.parseUnixTime(flightData.startedAt) : undefined,
      finishedAt: flightData.finishedAt > 0 ? Dates.parseUnixTime(flightData.finishedAt) : undefined,
      updatedAt: Dates.parseUnixTime(flightData.updatedAt),
      createdAt: Dates.parseUnixTime(flightData.createdAt),
      authorId: flightData.userId,
    };

    return {
      flight,
      crew: (data.crew as Array<any>)?.map(x =>{ return { id: x.memberId, title: x.fullName}; }),
      crewNumber: (flightData.crewDto as any)?.crewNumber ?? '',
      availableRoutes: (routesFlight.data as Array<any>)?.map(x =>{ return { id: x.routeId, title: x.routeName }; }),
      videoCount: data.videoCount ?? undefined,
      photoCount: data.photoCount ?? undefined,
      incidentCount: data.incidentCount ?? undefined,
      gasCount: data.gazCount ?? undefined,
      ofpCount: data.ortophotoCount ?? undefined,
    };
  }

  public async getFlightAvailableRoutes(): Promise<FlightExt | undefined> {
    const path = urlJoin([API, `/flights/available-routes/${getUserId()}`]);
    return await this.clientRest.get(path);
  }

  public async uploadRoutes(file: File): Promise<void | undefined> {
    const formData = new FormData();
    formData.append('file', file);
    const path = urlJoin([API, `/routes`]);
    return await this.clientRest.post(path, formData);
  };

  public async create(): Promise<string> {
    const createFlightParams = {
      userId: getUserId(),
      name: '',
      crew: { crewNumber: "", memberNames: [] }, // косяк апишки - поле обязательное для создания
    };

    const path = urlJoin([API, '/flights']);
    const resp = await this.clientRest.post(path, createFlightParams);
    return resp.data.flightId;
  }

  public async downloadFlightTelemetry(flightId: string, projectId: string, type = Formats.TLM): Promise<void> {
    const path = urlJoin([API, '/imagetile/download', `?flightId=${flightId}`, `?type=${type}`, `?projectId=${projectId}`]);

    emulateDownload(path);
  }

  public async flightName(flightId: string): Promise<string> {
    return this.clientGraphQl
      .query<FlightNameResponse>(
        FlightNameDocument,
        { id: flightId },
        REQUEST_POLICY_NETWORK,
      )
      .then((data) => data.flight?.flight_name ?? '');
  }

  public async downloadFlight(flightId: string): Promise<void> {
    emulateDownload(
      urlJoin([FlightImplApi.API_BASE, FlightImplApi.PATHS.flights, `/${flightId}`, FlightImplApi.PATHS.download]),
    );
  }

  public async saveFlightData(
    data: Flight,
    crew: readonly { id: string, title: string }[],
    crewNumber: string,
    workflowStatus?: string,
  ): Promise<Flight | undefined> {

    try {
      /*
        Тут пока явно перечисляем те значения, которые мы можем сохранить.
        Кстати пока непонятно - все поля должны быть переданы, или не переденные просто считаются измененными.
        Но  для сохранения - мы все берем все известные
      */
      const updatedData = {
        name: data.name,
        startedAt: Dates.dateToUnix(data.startedAt),
        finishedAt: Dates.dateToUnix(data.finishedAt),
        routeId: data.routeId,
        workflowStatusId: workflowStatus ?? data.workflowStatus,
        flightNumber: data.flightNumber,
        crew: {
          crewNumber,
          members: crew.map(member => ({ memberId: member.id, fullName: member.title }))
        },
      };

      const path = urlJoin([API, '/flights', `${data.flightId}`]);

      const resp = await this.clientRest.put(path, updatedData, { userId: getUserId() });
      if (resp.data) {
        return (
          message.success('Успешно сохранено')
        );
      }

    } catch (error) {
      log.error('failed to save fl entity', error, data.flightId);
      message.error('Ошибка сохранения данных');
      return undefined;
    }
  }

  async fetchFlight(flightId: string): Promise<OldFlight | undefined> {
    const requestParams: FlightQueryVariables = {
      id: flightId,
    };

    return this.clientGraphQl
      .query<FlightQueryResult>(
        FlightDocument,
        requestParams,
        REQUEST_POLICY_NETWORK,
      )
      .then((data) => {
        /**
         * Если полёт не пришел, то никаких дальнейших модификаций с объектом проводить не требуется
         */
        if (isNil(data.flight)) return undefined;

        const flight = data.flight;

        return {
          ...flight,
          geometry: {
            ...flight?.geometry,
            first_coord: GraphQlApi.parseCoordinates(
              flight?.geometry?.first_coord,
            ),
          },
        } as OldFlight;
      });
  }

  async deleteFlight(flightId: string): Promise<void> {
    const requestParams: UpdateFlightMutationVariables = {
      status: FlightStatuses.Deleted,
      id: flightId,
    };

    await this.clientGraphQl.mutation(
      UpdateFlightDocument,
      requestParams,
      REQUEST_POLICY_NETWORK,
    );
  }

  async getTrack(flightId: string, trackSource: TrackSource): Promise<TrackData> {
    const path = urlJoin([API, 'flights', flightId, 'track']);
    const res = await this.clientRest.get(path, {trackSource});
    return res.data;
  }

  //TODO переделать на два поля с одной структуры
  async downloadKmlAttachments(
    params: DownloadKmlParams,
  ): Promise<void> {
    const flightId = params.flight_id ?? params.id;
    const flightName = params.flight_name ?? params.text;

    if (isNil(flightId) && isNil(flightName) && isNil(params.project_id)) {
      throw new Error('Wrong Arguments: no flight or flightName passed');
    }

    emulateDownload(urlJoin([API, `/imagetile/download`, `?flightId=${flightId}`, `?projectId=${params.project_id}`, `?type=${Formats.KML}`]))
  }
}

export const createFlightApi = (): FlightApi => {
  return new FlightImplApi();
};
