import { useEffect, type FC } from 'react';
import { MapPluginProps } from '@/shared/map/model/interfaces';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource, Cluster } from 'ol/source';
import { Style, Circle, Fill, Text } from 'ol/style';
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import { isNil, maxBy, toNumber } from 'lodash';
import { implicitMapCheck } from '@/shared/map';
import { Options } from 'ol/source/Vector';
import { FeaturesType } from '@/entities/features';

type ClusterLayer = VectorLayer<VectorSource<Feature<Geometry>>>;
type ClusterFeature = Feature<Geometry>;
type ClusterStyle = (feature: Feature<Geometry>) => Style | undefined;
type ClusterOptions = Options<ClusterFeature> | undefined;

const DEFAULT_ZINDEX = 5;
const DEFAULT_STYLE_RADIUS = 20;
const DEFAULT_CLUSTER_DISTANCE = 30;
const DEFAULT_STYLE_PADDING = [10, 10, 10, 10];

export type TooltipTextFormatter = (maxFeatureInCluster: ClusterFeature) => {
  readonly text: string;
  readonly prefix: string;
};

interface ClusterLayerProps extends MapPluginProps {
  readonly layerId: string;
  readonly source: Options<ClusterFeature>;
  readonly styleCreator?: ClusterStyle;
  readonly nameCreator?: (layer: ClusterLayer) => string;
  readonly tooltipTextFormatter?: TooltipTextFormatter;
}

// Функция по умолчанию для создания имени слоя
const defaultNameCreator = (layer: ClusterLayer): string => 'cluster-' + layer.get('id');

export const ClusterLayer: FC<ClusterLayerProps> = ({
  map = implicitMapCheck(),
  source,
  styleCreator,
  nameCreator = defaultNameCreator,
  tooltipTextFormatter,
  layerId,
}) => {
  // Создание источника данных для кластера
  const createVectorSource = (source: ClusterOptions): VectorSource => {
    return new VectorSource(source);
  };

  // Функция для расчёта информации о кластерах
  const calculateClusterConcentration = (feature: ClusterFeature): number => {
    const features: readonly ClusterFeature[] = feature.get('features');

    feature.set('type', FeaturesType.CLUSTER);

    const maxFeature = maxBy(features, feature => toNumber(feature.get('name')));

    if (isNil(maxFeature)) return 0;

    const formatter = tooltipTextFormatter?.(maxFeature);

    if (formatter) {
      const { text, prefix } = formatter;
      feature.set('text', text);
      feature.set('prefix', prefix);
    }

    return toNumber(maxFeature?.get('name'));
  };

  // Функция по умолчанию для создания стиля кластера
  const createClusterStyle = (feature: ClusterFeature): Style | undefined => {
    const concentration = calculateClusterConcentration(feature);

    if (concentration > 100) {
      return new Style({
        image: new Circle({
          radius: DEFAULT_STYLE_RADIUS,

          // $blue-main / $bg-accent
          fill: new Fill({ color: '#177ae5' }),
        }),
        text: new Text({
          text: concentration.toString(),
          padding: DEFAULT_STYLE_PADDING,

          // $white-main / $text-primary-inverse
          fill: new Fill({ color: '#fff' }),
        }),
      });
    }

    return;
  };

  // Создание объекта кластеризации
  const createCluster = (source: VectorSource): Cluster => {
    return new Cluster({
      distance: DEFAULT_CLUSTER_DISTANCE,
      source,
    });
  };

  // Создание слоя карты с кластером
  const createVectorLayer = (layerId: string, source: Cluster, style: ClusterStyle): ClusterLayer => {
    const layer = new VectorLayer({ source, style, zIndex: DEFAULT_ZINDEX });

    layer.set('name', nameCreator(layer));
    layer.set('id', layerId);

    return layer;
  };

  // Извлечение имени слоя
  const parseLayerName = (layer: ClusterLayer): string => {
    const layerName: string | undefined = layer.get('name');

    if (isNil(layerName)) {
      throw new Error('Cluster layer name must be specified');
    }

    return layerName;
  };

  // Удаление слоя с карты
  const destroyVectorLayer = (layer: ClusterLayer): void => {
    map.removeLayerByName(parseLayerName(layer)); // Удаляем слой по имени
  };

  // Добавление слоя на карту
  const addLayerToMap = (layer: ClusterLayer): void => {
    map.addLayer(layer);
  };

  // Основная функция создания слоя с кластером
  const createClusterLayer = (source: ClusterOptions, styles: ClusterStyle) => {
    const vectorSource = createVectorSource(source);
    const vectorCluster = createCluster(vectorSource);

    return createVectorLayer(layerId, vectorCluster, styles);
  };

  useEffect(() => {
    const clusterLayer = createClusterLayer(source, styleCreator ?? createClusterStyle);
    addLayerToMap(clusterLayer);

    return () => {
      destroyVectorLayer(clusterLayer);
    };
  }, [source, styleCreator, createClusterStyle]);

  return null;
};
