import { logger } from '@workspace/4Z1.ts.utils';
import { LocalStorageKeys } from './interfaces';

const log = logger('LOC');

//Нужно для локализации uikit
//TODO требует рефача, возможно можно заменить и как-то по другому передавать locale

const DEFAULT_LOCALE = 'ru-RU';

// TODO подумать над рефачом:
// - положить в другое место
// - поработать над смыслом и названием (тут не только get, но и установка дефолтного)
// - подумать над выделением единого места для управления локализацией
// - подумать над изоляцией локал стораджа (в разных местах аппки обращения к ключу ClientLocale происходят по-разному)

export const getLocale = () => {
    const storedLocale = localStorage.getItem(LocalStorageKeys.ClientLocale);

    if (storedLocale) {
        try {
            const parsedLocale = JSON.parse(storedLocale);
            return parsedLocale;
        } catch (error) {
            log.error('parse error locale', error);
        }
    }

    // значения нет или оно "кривое" - положим дефолтное
    localStorage.setItem(LocalStorageKeys.ClientLocale, JSON.stringify(DEFAULT_LOCALE));
    return DEFAULT_LOCALE;
};