import { SortableFileProperties, UploadHistoryFile, UploadHistoryFileStatuses, UploadHistoryService } from '@/entities/uploadHistory';
import { State, StateHandler } from '@/shared/utils';
import { ApiQueryBuilder, ComparisonOperator, logger, SortDirection } from '@workspace/4Z1.ts.utils';
import { inject, injectable } from 'inversify';
import { isEmpty } from 'lodash';
import { makeAutoObservable } from 'mobx';
import RequestHandler from '@/shared/utils/requestHandler';

const log = logger('DownloadHistory');

export interface DownloadHistoryStoreState {
  readonly downloadHistory: readonly UploadHistoryFile[];
}

export interface TableFilters {
  readonly zalaFileType: string;
  readonly flightId: string;
  readonly createdAt: string;
  readonly status: string;
  readonly fileName: string;
  readonly fileSize: string;
}

export interface DownloadHistoryStore {
  readonly state: State<DownloadHistoryStoreState, unknown>;
  readonly onColumnSort: (key: keyof TableFilters, sorting?: SortDirection) => void;
  readonly mount: (flightId: string) => void;
  readonly unmount: () => void;
  readonly getFieldSorting: (key: keyof TableFilters) => void;
}

@injectable()
export class DownloadHistoryStoreImpl implements DownloadHistoryStore {
  /** Статичные переменные класса */
  private readonly POLLING_INTERVAL_MS = 5000;

  /** Переменные класса */
  private files: readonly UploadHistoryFile[] = [];
  private pollingTimeout: NodeJS.Timeout | undefined = undefined;
  private queryBuilder: ApiQueryBuilder<SortableFileProperties> = new ApiQueryBuilder<SortableFileProperties>();

  /** Подключение сервисов */
  private readonly uploadHistoryService: UploadHistoryService;

  constructor(
    @inject(UploadHistoryService.diKey) uploadHistoryService: UploadHistoryService,
    private readonly requestHandler = new RequestHandler<readonly UploadHistoryFile[]>(),
    private readonly pollingHandler = new RequestHandler<readonly UploadHistoryFile[]>(),
    private readonly filterHandler = new RequestHandler<readonly UploadHistoryFile[]>(),
  ) {
    makeAutoObservable(this);
    this.uploadHistoryService = uploadHistoryService;
  }

  public get state(): State<DownloadHistoryStoreState, unknown> {
    const { isLoading, error } = this.requestHandler;

    if (isLoading) {
      return StateHandler.loading();
    }

    if (error) {
      return StateHandler.error(error ?? 'An error occured during component rendering');
    }

    if (!isEmpty(this.files)) {
      return StateHandler.ready({
        downloadHistory: this.files,
      });
    }

    log.error('An unknown component state met ::', this.files, error);

    return StateHandler.error('Couldn`t resolve the state of component');
  }

  public mount(flightId: string): void {
    this.queryBuilder.addFilter('flightId', [{ operator: ComparisonOperator.EQUALS, value: flightId }]);
    this.fetchAndPullFiles();
    log.debug('Successfully mounted');
  }

  public unmount(): void {
    clearInterval(this.pollingTimeout);

    this.queryBuilder.clearFilters();
    this.queryBuilder.clearPagination();
    this.queryBuilder.clearSorts();

    log.debug('Successfully unmounted');
  }

  private async fetchUploadHistoryFiles(
    withPolling: boolean = false,
    requestHandler?: RequestHandler<readonly UploadHistoryFile[], unknown>,
  ): Promise<readonly UploadHistoryFile[]> {
    const handler = withPolling ? this.pollingHandler : this.requestHandler;

    const files = await (requestHandler ?? handler)
      .handleRequest(() => this.uploadHistoryService.fetchFilesByFlightId(this.queryBuilder))
      .then(files => {
        log.debug('Files have been retrieved ::', files);

        return files;
      });

    this.files = [...files];

    return files;
  }

  private async pullUploadHistoryFiles(): Promise<void> {
    if (this.pollingTimeout) {
      log.debug('Clearing existing polling timeout');
      clearTimeout(this.pollingTimeout);
    }

    if (!this.shouldPollFiles) {
      log.debug('Loading files were not found. Polling stopped');
      return;
    }

    const pollingTimeout = setTimeout(() => this.fetchAndPullFiles(true), this.POLLING_INTERVAL_MS);

    this.pollingTimeout = pollingTimeout;
  }

  private async fetchAndPullFiles(withPolling: boolean = false): Promise<void> {
    this.fetchUploadHistoryFiles(withPolling).finally(() => this.pullUploadHistoryFiles());
  }

  public onColumnSort(key: keyof TableFilters, sorting?: SortDirection): void {    
    this.queryBuilder.clearSorts();

    if (sorting) {
      this.queryBuilder.addSort(key, sorting);
    }
    
    this.fetchUploadHistoryFiles(false, this.filterHandler);
  }

  public getFieldSorting(key: keyof TableFilters): SortDirection | undefined {
    return this.queryBuilder.buildQuery().sort?.find(sorting => sorting.field === key)?.dir;
  }

  private get shouldPollFiles(): boolean {
    return this.files.some(file => file.status === UploadHistoryFileStatuses.Loading);
  }
}
