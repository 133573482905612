import moment from 'moment/moment';
import { DATE_FORMAT } from '@/shared/utils';
import { FlightItem } from '@/entities/flight/api/flight.api';
import { isNil } from 'lodash';

export const Flights = {

  /**
   * Формирование строки даты для имени полета
   */
  formatDateToFlightName: (date: Date | undefined): string => {
    return date ? moment(date).format(DATE_FORMAT) : '';
  },

  /**
   * Имя маршрута по его идентификатору
   */
  getRouteName: (routeId?: string, routesList?: readonly FlightItem[]): string => {
    if (!isNil(routeId) && !isNil(routesList) ) {
      const route = routesList.find(route => routeId === route.id);
      if (route !== undefined) return route.title;
    }

    return '';
  },

  /**
   * Формирование имени полета по его данным
   */
  createSynteticName: (
    flight: { routeId?: string | undefined, startedAt?: Date | undefined, flightNumber?: string | undefined},
    crewNumber: string,
    rotes?: readonly FlightItem[],
  ) => {
    const routeName = Flights.getRouteName(flight.routeId, rotes);
    const startDate = Flights.formatDateToFlightName(flight?.startedAt);
    const numberFlight = flight?.flightNumber ?? '';
    const crewNum = crewNumber ?? '';

    const parts = [
      routeName.trim(),
      startDate.trim(),
      crewNum.trim(),
      numberFlight.trim()
    ].filter(part => part.length > 0);

    return parts.join('_');
  }

} as const;
