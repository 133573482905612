import { ZalaType } from '@/entities/zalaType'; // TODO нужно переделать, не гоже импортировать из другой энтити
import { EventBus } from '@/shared/utils/eventBus';

export enum FlightStatuses {
  Deleted = 0,
  Active = 1,
  Inactive = 2,
}

export enum FlightTypes {
  Video = 1,
  Photo = 2,
  Violation = 3,
  Gaz = 4,
}

export interface FlightWorkflowStatus {
  readonly id: string;
  readonly name: string;
}

export enum RouteStatuses {
  Deleted = 0,
  Active = 1,
}

// TODO - возможно используется в истории - рассмотреть возможность переиспользования
export interface FlightUploadHistoryItem {
  readonly createdAt: string,
  readonly fileName: string,
  readonly fileSize: number,
  readonly fileType: string,
  readonly finishedAt: string,
  readonly flightId: string,
  readonly id: number,
  readonly offset: number,
  readonly status: FlightUploadHistoryItemStatus,
  readonly username: string,
  readonly zalaFileType: ZalaType,
}

export enum FlightUploadHistoryItemStatus {
  Unknown = 'Unknown',
  CreateUpload = 'CreateUpload',
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  Stored = 'Stored',
  Processing = 'Processing',
  Finished = 'Finished',
  Error = 'Error',
  Rejected = 'Rejected',
  Terminated = 'Terminated',
  Deleted = 'Deleted',
  Archieved = 'Archieved',
}

export const TERMINAL_FLIGHT_UPLOAD_HISTORY_ITEM_STATUSES: readonly FlightUploadHistoryItemStatus[] = [
  FlightUploadHistoryItemStatus.Unknown, 
  FlightUploadHistoryItemStatus.Finished, 
  FlightUploadHistoryItemStatus.Error,
  FlightUploadHistoryItemStatus.Rejected,
  FlightUploadHistoryItemStatus.Terminated,
  FlightUploadHistoryItemStatus.Deleted,
  FlightUploadHistoryItemStatus.Archieved,
];

export const NON_TERMINAL_FLIGHT_UPLOAD_HISTORY_ITEM_STATUSES: readonly FlightUploadHistoryItemStatus[] = [
  FlightUploadHistoryItemStatus.CreateUpload,
  FlightUploadHistoryItemStatus.Uploading,
  FlightUploadHistoryItemStatus.Uploaded,
  FlightUploadHistoryItemStatus.Stored,
  FlightUploadHistoryItemStatus.Processing,
];
export enum TrackSource {
  Photo = 'Photo',
  Video = 'Video',
  Gas = 'Gas',
}

export interface TrackData {
  readonly type: string;
  readonly coordinates: [number, number][];
}

// region
// Должно лежать ближе к странице FlightCard, т.к используется только там
// Временный костыль. Удалить, когда избавимся от EventBus
export enum FlightEventsKeys {
  onChanged = 'onChanged',
  onSave = 'onSave',
  onCancel = 'onCancel',
}

export enum FlightChangeEventsKeys {
  onChildChanged = 'onChildChanged',
  onParentChanged = 'onParentChanged',
}

export interface FlightChangeEvent {
  readonly key: FlightChangeEventsKeys;
  readonly value: boolean;
}

interface FlightEventBusEvents {
  readonly [FlightEventsKeys.onCancel]: unknown;
  readonly [FlightEventsKeys.onSave]: boolean;
  readonly [FlightEventsKeys.onChanged]: FlightChangeEvent;
}

export type FlightEventBus = EventBus<FlightEventBusEvents>;
// endregion