import { defaultTo, get, isNil } from 'lodash';
import { SortableFileProperties, UploadHistoryFile, UploadHistoryFileStatuses } from '@/entities/uploadHistory';
import { ApiQueryBuilder, logger } from '@workspace/4Z1.ts.utils';
import { createRestApi } from '@/shared/api/rest';
import urlJoin from 'url-join';
import { ZalaType } from '@/entities/zalaType';
import { mockDataList } from './utils';

const log = logger('Api:UploadHistoryFile');

enum UploadStatus {
  Unknown = 'Unknown',
  CreateUpload = 'CreateUpload',
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  Stored = 'Stored',
  Processing = 'Processing',
  Finished = 'Finished',
  Error = 'Error',
  Rejected = 'Rejected',
  Terminated = 'Terminated',
  Removed = 'Removed',
  Deleted = 'Deleted',
  Archived = 'Archived',
}

export interface UploadHistoryApi {
  readonly fetchFiles: (query: ApiQueryBuilder<SortableFileProperties>) => Promise<readonly UploadHistoryFile[]>;
}

interface FilesResponse {
  readonly createdAt: string;
  readonly fileName: string;
  readonly fileSize: number;
  readonly fileType: string;
  readonly finishedAt: string;
  readonly flightId: string;
  readonly id: number;
  readonly offset: number;
  readonly status: UploadStatus;
  readonly username: string;
  readonly zalaFileType: ZalaType;
}

class UploadHistoryImpl implements UploadHistoryApi {
  private static readonly API_BASE = API;

  private static readonly FILES = '/files/search';

  private static readonly PATHS = {
    searchFiles: urlJoin(UploadHistoryImpl.API_BASE, UploadHistoryImpl.FILES),
  } as const;

  private readonly client = createRestApi();

  async fetchFiles(query: ApiQueryBuilder<SortableFileProperties>): Promise<readonly UploadHistoryFile[]> {
    /** 
     * Временное решение, чтобы возвращались все файлы
     * Удалить, когда будет готова пагинация на табе 'История загрузок'
    */
    query.setPagination(0, 100_000);

    const response = await this.client.post<readonly FilesResponse[]>(UploadHistoryImpl.PATHS.searchFiles, query.buildQuery());

    const rawFiles = resolveRawFiles(response?.data);

    return transformUploadHistoryFiles(rawFiles);
  }
}

export const createUploadHistoryFileApi = (): UploadHistoryApi => {
  return new UploadHistoryImpl();
};

const FILE_STATUSES_MAP: Partial<Record<UploadStatus, UploadHistoryFileStatuses>> = {
  [UploadStatus.Unknown]: UploadHistoryFileStatuses.Error,
  [UploadStatus.CreateUpload]: UploadHistoryFileStatuses.Preprocessing,
  [UploadStatus.Uploading]: UploadHistoryFileStatuses.Loading,
  [UploadStatus.Uploaded]: UploadHistoryFileStatuses.Processing,
  [UploadStatus.Stored]: UploadHistoryFileStatuses.Processing,
  [UploadStatus.Processing]: UploadHistoryFileStatuses.Processing,
  [UploadStatus.Finished]: UploadHistoryFileStatuses.Finished,
  [UploadStatus.Error]: UploadHistoryFileStatuses.Error,
  [UploadStatus.Rejected]: UploadHistoryFileStatuses.InternalServerError,
  [UploadStatus.Terminated]: UploadHistoryFileStatuses.Denied,
  [UploadStatus.Removed]: UploadHistoryFileStatuses.Deleted,
  [UploadStatus.Deleted]: UploadHistoryFileStatuses.Deleted,
  [UploadStatus.Archived]: UploadHistoryFileStatuses.Finished,
} as const;

const transformUploadHistoryFiles = (files: readonly FilesResponse[]): readonly UploadHistoryFile[] => {
  return files.map(file => ({
    id: file.id.toString(),
    type: file.fileType,
    fileName: file.fileName,
    fileSize: file.fileSize,
    progress: getFilePercentage(file.offset, file.fileSize),
    uploadedSize: file.offset,
    zalaType: file.zalaFileType,
    status: parseFileStatus(file.status),
    createdAt: file.createdAt,
  }));
};

const getFilePercentage = (loadedSize: number, fileSize: number, maxDigits = 0): string => {
  return (loadedSize * 100 / fileSize).toFixed(maxDigits);
}

const parseFileStatus = (status: UploadStatus): UploadHistoryFileStatuses => {
  /**
   * TODO - Необходимо определить поведение функции, если тип статуса не был найден
   *
   * На данный момент проверка ?? status является костылем, чтобы отображать пустую строчку вместо статуса
   * В будущем необходимо обрабатывать все возможные статусы
   */
  const mappedStatus = defaultTo(get(FILE_STATUSES_MAP, status), UploadHistoryFileStatuses.Unknown);

  if (isNil(mappedStatus)) {
    log.error('An unknown status met during status parsing :: ', status);
  }

  return mappedStatus;
};

/**
 * @dev-only
 * Возвращает файлы из предоставленного массива необработанных файлов истории загрузки (rawFiles)
 * Эта функция используется в режиме разработки для тестирования с использованием локальных настроек из `localStorage`.
 * 
 * Если ключ dev__dhistMocks указан в localStorage, то вернет моковые данные, иначе вернет сырые данные без обработки
 */
const resolveRawFiles = (rawFiles: readonly FilesResponse[]): readonly FilesResponse[] => {
  const devLocalStorageKeys = {
    replaceWithMocks: 'dev__dhistMocks',
  } as const;

  const shouldReplaceWithMocks: string | undefined =
    localStorage.getItem(devLocalStorageKeys.replaceWithMocks) ?? undefined;

  if (shouldReplaceWithMocks) {
    log.debug('Received dev__dhistMocks ::', shouldReplaceWithMocks);
    log.debug('Forcing resolver to return mockData', mockDataList);
    return mockDataList;
  }

  return rawFiles;
};
