import { createRestApi, RestApi } from '@/shared/api/rest';
import { ApiQueryBuilder, ComparisonOperator, LogicOperator } from '@workspace/4Z1.ts.utils';
import urlJoin from 'url-join';
import { FlightUploadHistoryItem, NON_TERMINAL_FLIGHT_UPLOAD_HISTORY_ITEM_STATUSES,  } from '../model/interfaces';

export interface PollingApi {
  fetchUpdates: (flightId: string) => Promise<readonly FlightUploadHistoryItem[]>; 
}

class PollingImplApi implements PollingApi {
  private readonly restApi: RestApi = createRestApi();

  private static readonly PATH = urlJoin([API, '/files/search']);

  public async fetchUpdates(flightId: string): Promise<readonly FlightUploadHistoryItem[]> { 
    const query= new ApiQueryBuilder()
      .addFilter('flightId', [{ operator: ComparisonOperator.EQUALS, value: flightId }])
      .addFilter(
        'status', 
        NON_TERMINAL_FLIGHT_UPLOAD_HISTORY_ITEM_STATUSES.map(value => ({ operator: ComparisonOperator.EQUALS, value })), 
        LogicOperator.OR
      )
      .buildQuery();
    
    const response = await this.restApi.post(PollingImplApi.PATH, query);
    return response.data;
  }
}

export const createPollingApi = (): PollingApi => {
  return new PollingImplApi();
};