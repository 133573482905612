import { injectable } from 'inversify';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { createPollingApi, type PollingApi } from '../api/polling.api';
import { FlightUploadHistoryItem, NON_TERMINAL_FLIGHT_UPLOAD_HISTORY_ITEM_STATUSES } from './interfaces';
import { ZalaType } from '@/entities/zalaType';
import { logger } from '@workspace/4Z1.ts.utils';

const log = logger('FL:O:S');

const DEFAULTS = {
  LONG_INTERVAL: 60 /* sec */ * 1000,
  SHORT_INTERVAL: 3 /* sec */ * 1000,
} as const;

export interface FlightObservationListener {
  onFlightUpdated(flightId: string, type: ZalaType): void;
}

// TODO можно следить не только за одним полетом, а организовать pub-sub для этого. и тогда доку нужно будет поправить )
/**
 * Сервис для наблюдения за изменениями одного(!) полета
 */
@injectable()
export class FlightChangesObservationService {
  public static readonly diKey = Symbol.for('FlightChangesObservationService');

  private previousData: FlightUploadHistoryItem[] = [];
  private currentFlightId: string | undefined = undefined;
  private pollingTimeoutId: number | undefined = undefined;
  private pollingInterval: number = DEFAULTS.LONG_INTERVAL;
  private readonly fastPollingInterval = DEFAULTS.SHORT_INTERVAL;
  private readonly normalPollingInterval = DEFAULTS.LONG_INTERVAL;

  private isPollingActive: boolean = false;

  private lastTypes: Set<ZalaType> | undefined = undefined;

  private readonly listeners: FlightObservationListener[] = [];

  constructor(
    private readonly pollingApi: PollingApi = createPollingApi(),
  ) {
    makeAutoObservable(this);
    this.pollingApi = pollingApi;
  }

  /**
   * Запускает поллинг для указанного flightId.
   */
  @action
  public observe(flightId: string): void {
    if (this.currentFlightId === flightId && this.isPollingActive) {
      return; // Поллинг уже запущен для этого flightId
    }
    this.stopPolling(); // Останавливаем предыдущий поллинг, если он был
    this.currentFlightId = flightId;
    this.pollingInterval = this.normalPollingInterval;

    this.startPollingLoop();
  }

  /**
   * Останавливает поллинг.
   */
  @action
  public stopPolling(): void {   
    if (this.pollingTimeoutId) {
      clearTimeout(this.pollingTimeoutId);
      this.pollingTimeoutId = undefined;
    }
    this.isPollingActive = false;
    this.currentFlightId = undefined;
  }

  /**
   * Запускает цикл поллинга.
   */
  private startPollingLoop(): void {
    this.isPollingActive = true;
    
    if (this.pollingTimeoutId) {
      clearTimeout(this.pollingTimeoutId);
    }

    const poll = async () => {
      if (!this.currentFlightId) {
        return;
      }

      try {
        await this.checkUpdates(this.currentFlightId);
      } catch (e) {
        log.error('Something is wrong during checking for updates', this.currentFlightId, e);
      }

      // Запускаем следующий цикл поллинга
      if (this.isPollingActive) {
        this.pollingTimeoutId = window.setTimeout(poll, this.pollingInterval);
      }
    };

    // Запускаем первый цикл поллинга
    poll();
  }

  private async checkUpdates(flightId: string): Promise<void> {
    const rawData = await this.pollingApi.fetchUpdates(flightId);

    // TODO убрать фильтрацию - это должен делать бэк
    const data = rawData.filter(item => NON_TERMINAL_FLIGHT_UPLOAD_HISTORY_ITEM_STATUSES.includes(item.status));

    const activeTypes = data.reduce((types, item) => types.add(item.zalaFileType), new Set<ZalaType>());

    this.pollingInterval = activeTypes.size > 0 ? this.fastPollingInterval : this.normalPollingInterval;
    // - сравнить состояние с предыдущим - если какие-то загрузки закончились - то триггернуть событие
    if (this.lastTypes) {
      // нужно получить список, который был в lastTypes, но его нет в active
      const typesBecomeInactive = this.lastTypes.difference(activeTypes);
      this.notifyTypesBecomeInactive(typesBecomeInactive);
    }

    this.lastTypes = activeTypes;
  }

  private notifyTypesBecomeInactive(types: Set<ZalaType>) {
    const flightId = this.currentFlightId;
    if (flightId === undefined || types.size === 0) return;
    types.forEach(type => this.listeners.forEach(listener => listener.onFlightUpdated(flightId, type )));
  }

  public addListener(listener: FlightObservationListener): void {
    this.listeners.push(listener);
  }

  public removeListener(listener: FlightObservationListener): void {
    this.listeners.removeItem(listener);
  }

  @action
  public waitingForChanges(): void {
    this.pollingInterval = this.fastPollingInterval;
    if (this.pollingTimeoutId) {
      clearTimeout(this.pollingTimeoutId);
      this.startPollingLoop(); // Перезапуск поллинга с новым интервалом
    }
  }

}