import { injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';
import { IntlShape } from 'react-intl';

/**
 * Сервис для доступа к локализации не из tsx
 */
@injectable()
export class LocalizationService {
  public static readonly diKey = Symbol.for('LocalizationService');

  private locale!: IntlShape;


  constructor() {
    makeAutoObservable(this);
  }

  public get intl(): IntlShape {
    if (this.locale === undefined) {
      throw new Error('Illegal state exception: no localization provided');
    }
    return this.locale;
  }


  public setLocale(intl: IntlShape): void {
    this.locale = intl;
  }

  
}