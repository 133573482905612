import { transformCoordFormat } from '@/components/Map/transformCoordinates';
import { FC } from 'react';
import { useCookies } from 'react-cookie';
import './styles.scss';
import { Coordinates } from '@workspace/4Z1.ts.utils';

interface Props {
  readonly coordinates: Coordinates;
  readonly subtitle?: string;
}

export const PhotoDescriptionPanel: FC<Props> = ({
  coordinates,
  subtitle,
}) => {
  const [cookies] = useCookies(['reprojection']);

  return (
    <div className="text-panel">
      {transformCoordFormat(
        [coordinates.lon, coordinates.lat],
        cookies.reprojection,
      )}
     {subtitle &&  <div>{subtitle}</div>}
    </div>
  );
};
