export default {
  'incident.form.status': 'Статус',
  'incident.form.relevance': 'Приоритет',
  'incident.form.type': 'Тип',
  'incident.form.targetCoordinates': 'Происшествие',
  'incident.form.mapCenterCoordinates': 'Центр координат',
  'incident.form.dateTime': 'Дата обнаружения',
  'incident.form.detectionMethod': 'Способ обнаружения',

  'incident.table.number': '№',
  'incident.table.source': 'Источник',
  'incident.table.type': 'Тип',
  'incident.table.detectionMethod': 'Способ',
  'incident.table.vltStatusId': 'Приоритет',
  'incident.table.status': 'Статус',

  'incident.actions.load': 'Загрузить',
  'incident.actions.download': 'Скачать',
  'incident.actions.delete': 'Удалить',
  'incident.actions.save': 'Сохранить',
  'incident.actions.reset': 'Сбросить',
  'incident.actions.toTheSource': 'К источнику',

  'incident.delete.title': 'Удаление происшествий',
  'incident.delete.body': 'Вы уверенны что хотите удалить происшествия?',

  'incident.tabs.map': 'Трек полета',
  'incident.tabs.attachments': 'Вложения',
  'incident.tabs.comments': 'Комментарии',

  'incident.noPreviousUploadFound': 'Происшествия не загружены. Перетащите сюда JPG/PDF-файлы или нажмите на кнопку.',

}
